import React from 'react';
import NavTabs from './components/NavTabs'
import './LandingPage.scss'




export default function LandingPage({handleToggleAccordian, handleListenState}) {

  return (
    <div  >
      <div className="demo1">
        <NavTabs handleToggleAccordian={handleToggleAccordian} handleListenState={handleListenState}/>
      </div>
    </div>
  );
}
