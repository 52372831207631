import React, {useContext } from 'react';
import {
    BodyText,
    InlineLink,
  } from '@hm-group/fabric-components'
import { retrievePrivacyLink, getkeyvalueText, GetCSEmailHtml, identifyEvent } from '../../../util/helperfunctions';
import { CustomerContext } from '../../../context/customerDetailsContext';
import { brandNameFromID } from '../../../util/constants';
import { UserContext } from '../../../context/userContext';


const AccessPersonalData = () => {
  const {customer} = useContext(CustomerContext);
  const isshowkey = false; // temporary
  const {
    corporateBrandId,
    countryCode,
  } = customer;


    const {lslKeyValue} = useContext(UserContext);

 

    const handlePrivacyAction = (brand, country) => {
        window.open(retrievePrivacyLink(brand, country,lslKeyValue), "_blank")
    }

    return (
      <div className="accessDataSection">
        <BodyText Tag="p">
          {getkeyvalueText('accessPersonalData', 'rt2requestinfoLabel', isshowkey, lslKeyValue.lslKeyData)}
        </BodyText>
        <BodyText Tag="p">
          {getkeyvalueText('accessPersonalData', 'recieveacopyinfoLabel', isshowkey, lslKeyValue.lslKeyData)}
        </BodyText>
        <BodyText Tag="p">
          {
            getkeyvalueText(
              'accessPersonalData',
              'lessprocesseddatacategoriesLabel',
              isshowkey,
              lslKeyValue.lslKeyData,
            ).split('{CS email}')[0]
          }{' '}
          {
            getkeyvalueText(
              'accessPersonalData',
              'lessprocesseddatacategoriesLabel',
              isshowkey,
              lslKeyValue.lslKeyData,
            ).split('{CS email}')[1]
          }
          <br />
        </BodyText>

        <GetCSEmailHtml
          brand={brandNameFromID[corporateBrandId]}
          country={countryCode}
          lslKeyData={lslKeyValue.lslKeyData}
          isshowkey={isshowkey}
          align={'left'}
        />
        <BodyText Tag="p">
          {
            getkeyvalueText('deletePersonalData', 'details4rulesLabel', isshowkey, lslKeyValue.lslKeyData).split(
              '{Privacy Notice LINK}',
            )[0]
          }
          <InlineLink
            target="_blank"
            data-testid="request_access_link"
            rel="noreferrer"
            onClick={() => {
              handlePrivacyAction(corporateBrandId, countryCode)
            }}
            onKeyDown={(event) => {
              identifyEvent(event) && handlePrivacyAction(corporateBrandId, countryCode)
            }}
          >
            {getkeyvalueText('LandingPage', 'privacynoticeLabel', isshowkey, lslKeyValue.lslKeyData)}
          </InlineLink>
          {
            getkeyvalueText('deletePersonalData', 'details4rulesLabel', isshowkey, lslKeyValue.lslKeyData).split(
              '{Privacy Notice LINK}',
            )[1]
          }
        </BodyText>
      </div>
    )
}

export default AccessPersonalData;