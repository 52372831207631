import React, { useContext } from 'react'
import {
  getkeyvalueText,
  GetCSEmailHtml,
  isCentraMarket,
  getDisplayBrandNamebyID,
  getBrandSite,
} from '../../../util/helperfunctions'
import { BodyText } from '@hm-group/fabric-components'
import moment from 'moment'
import { brandNameFromID } from '../../../util/constants'
import { CustomerContext } from '../../../context/customerDetailsContext'
import { CustomerDataContext } from '../../../context/customerDataContext'
import { UserContext } from '../../../context/userContext'

const NotifyDeleteProcess = ({isGuestInfoClicked}) => {
  const isshowkey = false // check this later
  const {lslKeyValue} = useContext(UserContext)
  const {customer} = useContext(CustomerContext)
  const {customerData} = useContext(CustomerDataContext)
  const { corporateBrandId, countryCode, consentRevocationDate } = customer
  const { guestBusinessPartnerId ,guestCICDate } = customerData
  const deletionDate = isGuestInfoClicked ? guestCICDate : consentRevocationDate

 // TODO : move to common function
  const getDateText = (date) => {
    if (date) {
      return String(moment(new Date(date)).format('DD MMMM YYYY'))
        .replace(
          moment(new Date(date)).format('MMMM'),
          getkeyvalueText('Month', moment(new Date(date)).format('MMMM'), isshowkey, lslKeyValue.lslKeyData),
        )
        .trim()
    } else {
      return ''
    }
  }


  return (
    <div>
      <BodyText Tag="p">
        {
          getkeyvalueText('LandingPage', 'deleterequestLabel', isshowkey, lslKeyValue.lslKeyData).split(
            '{dd mmm yyyy}',
          )[0]
        }
        {getDateText(deletionDate)}
        {getkeyvalueText('LandingPage', 'deleterequestLabel', isshowkey, lslKeyValue.lslKeyData)
          .split('{dd mmm yyyy}')[1]
          .trim()}
      </BodyText>
      <BodyText Tag="p">
        {getkeyvalueText('Lightboxes', 'notifydeletionprocessLabel', isshowkey, lslKeyValue.lslKeyData)}
      </BodyText>
      {isCentraMarket(brandNameFromID[corporateBrandId], countryCode) ? (
        <BodyText Tag="p">
          {getkeyvalueText('Lightboxes', 'noteciccronuslabel', isshowkey, lslKeyValue.lslKeyData)
            .replaceAll('{Brand}', `${getDisplayBrandNamebyID(corporateBrandId)}`)
            .replace('{brandsite}', getBrandSite(corporateBrandId))}
        </BodyText>
      ) : (
        ''
      )}
      {guestBusinessPartnerId ? (
        <BodyText Tag="p">
          {getkeyvalueText('Lightboxes', 'noaccntaccessLabel', isshowkey, lslKeyValue.lslKeyData)}
        </BodyText>
      ):(
        ''
      ) }
      <BodyText Tag="p">
        {getkeyvalueText('Lightboxes', 'contactCStype1Label', isshowkey, lslKeyValue.lslKeyData)}{' '}
      </BodyText>
      <GetCSEmailHtml
        brand={brandNameFromID[corporateBrandId]}
        country={countryCode}
        lslKeyData={lslKeyValue.lslKeyData}
        isshowkey={isshowkey}
        align={'left'}
      />
    </div>
  )
}

export default NotifyDeleteProcess
