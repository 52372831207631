import React, { useContext, useMemo, useCallback, useState } from "react";
import { TextInput, BodyText, PrimaryButton, SecondaryButton, HeadingLarge, ToastNotification } from "@hm-group/fabric-components";
import {
    getBrandImagebyID,
    getCountryNamebyCode,
    getkeyvalueText,
    getBrandIdbyName,
} from "../../util/helperfunctions";
import { cicConstants, EVENTTYPE, PAGE, EVENTACTION, EVENTOUTCOME, labelMappings } from "../../util/constants";
import { sendcicotpemailrequest } from "../../Services/DataService";
import { AnalyticsContext } from "../../context/analyticsContext";
import "./CICVerification.scss"
import { UserContext } from "../../context/userContext";
import { CustomerContext } from "../../context/customerDetailsContext";


/**
 * CICVerification Component
 * @param {Object} props - Component properties
 * @param {Function} props.handleCicContinue - Function to handle continue action
 * @returns {JSX.Element} The CIC Verification Component
 */
export const CICVerification = ({ handleCicContinue, onClose, payloadData }) => {
    // Contexts
    const { analyticsData, updateAnalyticsData, setAnalyticsData } = useContext(AnalyticsContext);
    const {lslKeyValue} = useContext(UserContext);

    const { userName, country, brand,businessPartnerId,clubRole } = payloadData;
    const { preferredLanguage, lslKeyData } = lslKeyValue;
    const { customer } = useContext(CustomerContext);
    const { corporateBrandId } = customer;

    // Memoized country name and brand ID
    const countryName = useMemo(() => getCountryNamebyCode(country, false, lslKeyData), [country, lslKeyData]);
    const brandId = useMemo(() => getBrandIdbyName(brand), [brand]);

    // states
    const isshowkey = false // temporary
    const [isApiFailed, setIsApiFailed] = useState(false)

    // Destructure formData for easier access

    const [isVisible, setIsVisible] = useState(true);

    const islslKey = false // TODO: Need to check this


    const handleToastClose = () => {
      setIsVisible(false)
    }

    /**
     * Updates analytics data with event outcome
     * @param {string} eventOutcome - The outcome of the event
     */
    const updateAnalytics = useCallback((eventOutcome,eventAction) => {
        updateAnalyticsData({
            ...analyticsData,
            managedCountry: country,
            managedBrand: `${brandId}`,
            eventType: EVENTTYPE.cicVerification,
            page: PAGE.cicAreYouSurePage,
            eventAction,
            eventOutcome,
            managedBPID: businessPartnerId,
            managedCustomerType: clubRole,
        });
    }, [analyticsData, brandId, businessPartnerId, clubRole, country, updateAnalyticsData]);

    // Memoized CIC OTP request parameters
    const postcicotpParams = useMemo(() => {
        const params = {
            emailid: userName,
            language: preferredLanguage,
            countryCode: country,
            brandId : getBrandIdbyName(brand),
            data: 'temp',
            cicCountryName: getCountryNamebyCode(country),
            customerType: clubRole,
        };
        Object.entries(labelMappings).forEach(([key, value]) => {
            params[key] = getkeyvalueText(value, key, false, lslKeyData);
        });

        return params;
    }, [userName, preferredLanguage, country, brandId, countryName, clubRole, lslKeyData]);

    /**
     * Sends CIC OTP email and updates analytics based on response
     */
    const sendcicotpemail = useCallback(async () => {
        try {
            const emailResponse = await sendcicotpemailrequest(postcicotpParams);
            const eventOutcome = emailResponse.status === 200 ? EVENTOUTCOME.success : EVENTOUTCOME.failure;
            updateAnalytics(eventOutcome,EVENTACTION.cicOtpSent);

            if (eventOutcome === EVENTOUTCOME.success) {
                handleCicContinue(cicConstants.cicVerificationFlow);
            }
            else{
                setIsApiFailed(true)
            }
        } catch (err) {
            updateAnalytics(EVENTOUTCOME.failure,EVENTACTION.cicOtpSent);
            setIsApiFailed(true)
        }
    }, [postcicotpParams, updateAnalytics, handleCicContinue]);

    return (
      <div className="pop_up_width">
        <div className="brand__img spacing">
          <img className="logoImage" src={getBrandImagebyID(corporateBrandId)} alt="Brand Logo" />
        </div>

        {isApiFailed ? (
          <div className={`apiSucessErrorToast ${isApiFailed ? 'toast-error' : 'toast-success'}`}>
          <ToastNotification
            autoDelete={false}
            labels={{
              close: 'Close'
            }}
            onClose={handleToastClose}
            isVisible={isVisible}
            message={getkeyvalueText('accessPersonalData', 'accessdataerrormsgLabel', islslKey, lslKeyValue.lslKeyData)}
            severity="error"
          />
          </div>
        ) : (
          ''
        )}

        <HeadingLarge Tag="h1" className="cicVerificationHeading spacing" tabIndex={0}>
          {getkeyvalueText('Lightboxes', 'areusureLabel', islslKey, lslKeyData)}
        </HeadingLarge>

        <BodyText Tag="p" className="spacing">
          {getkeyvalueText('Lightboxes', 'noundodeleteactionLabel', islslKey, lslKeyData)}
        </BodyText>
        <BodyText Tag="p" className="spacing">
          {getkeyvalueText('Lightboxes', 'loginntallowedLabel', islslKey, lslKeyData)}
        </BodyText>
        <BodyText Tag="p" className="spacing">
          {getkeyvalueText('Lightboxes', 'cicemailcodeLabel', islslKey, lslKeyData)}
        </BodyText>

        <TextInput
          className="spacing"
          isDisabled
          id="inputEmail"
          data-testid="inputEmail"
          value={userName}
          label={getkeyvalueText('login', 'userLabel', islslKey, lslKeyData)}
        />

        <div className="actionButton ">
          <PrimaryButton onClick={sendcicotpemail}>
            {getkeyvalueText('Lightboxes', 'custcodeLabel', islslKey, lslKeyData)}
          </PrimaryButton>
          <SecondaryButton
            onClick={() => {
              onClose(cicConstants.cicOtpFlow)
            }}
            className="spacing"
          >
            {getkeyvalueText('commonKey', 'cancelbtnLabel', islslKey, lslKeyData)}
          </SecondaryButton>
        </div>
      </div> 
    )
};
