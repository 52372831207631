
export const b2cPolicies = {

    names: {

        signUpSignIn: "B2C_1A_COS_AUS_SIGNUP_SIGNIN",

        editProfile: "B2C_1A_COS_AUS_SIGNUP_SIGNIN"

    },

    authorities: {

        signUpSignIn: {
             authority: process.env.REACT_APP_SIGN_IN_UP_AUTHORITY

        },

        editProfile: {
            authority: process.env.REACT_APP_EDIT_PROFILE_AUTHORITY


        }

    },
    
       authorityDomain: process.env.REACT_APP_AUTHORITY_DOMAIN


}