import axios from "axios";
// import LocalStorageService from "./LocalStorageService";
import router from '../routes/Routes';
// // LocalstorageService
import { useHistory } from 'react-router-dom';
// import {getAuthCode} from './LoginService'


// const localStorageService = LocalStorageService.getService();

let loginRetryCount = 0;

// Add a request interceptor
axios.interceptors.request.use(
    config => {
        if (!((config.url.includes('login') || config.url.includes('oauth')))) {
            const isvtagPresent = localStorage.getItem("vtag");
            const username = localStorage.getItem("userName");
            if (!(isvtagPresent == undefined || isvtagPresent == "" || isvtagPresent == null)) {
                config.headers['Authorization'] = isvtagPresent;
                config.headers['username'] = username;
            }
        }
        const brandCountry = localStorage.getItem("brandCountry");
        if (!(brandCountry == undefined || brandCountry == "" || brandCountry == null)) {
            config.headers['brandCountry'] = brandCountry;
        } else {
            config.headers['brandCountry'] = undefined;
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;

    },
    error => {
        Promise.reject(error)
    });



// //Add a response interceptor

axios.interceptors.response.use((response) => {
    return response
}, function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !error.config.url.includes('login')) {
        if (error.response.data == "Unauthorized") {
            localStorage.clear();
            window.location.href = "/Logoff";
        }
        // localStorage.clear();
        // window.location.href = "/Logoff";
       
    }

    // If Login Error Count is more than 2 , Return the execution thread
    // if (loginRetryCount >= 1) {
    //     loginRetryCount = 0;
    //     return;
    // }
    // //To Encounter timeOut API Transactions specially Login API
    // if ((error.response.status === 504 && error.response.config.url.includes("login"))) {
    //     return new Promise((resolve, reject) => {
    //         // console.log(`[${getCurrentDate()}] `, "Status=>", error.response.status, "Login originalRequest", originalRequest.url, `with retryCount=>${loginRetryCount}`);
    //         axios.request(originalRequest).then(response => {
    //             loginRetryCount = 0;
    //             resolve(response);
    //         }).catch((error) => {
    //             ++loginRetryCount
    //             // console.log(`[${getCurrentDate()}] `, `Interceptor Encountered error with Login API=>${error.response.status}, will proceed with Strike 1 API Failover Recovery=> API Call Resent=>${originalRequest.url} and Rejected with error=>${error.response.data}`);
    //             // reject(error);
    //             return Promise.reject(error);
    //         })
    //     });
    // }

    //    if (error.response.status === 401 && !originalRequest._retry && !error.response.config.url.includes("login")) {

    //         // alert(error.response)

    //        originalRequest._retry = true;
    //        const refreshToken = localStorageService.getRefreshToken();
    //        getAuthCode()
    //     //    return axios.post('/auth/token',
    //     //        {
    //     //            "refresh_token": refreshToken
    //     //        })
    //            .then(res => {
    //                // console.log(res,"Res");
    //                if (res.status === 200) {
    //                    localStorageService.setToken(res.data.access_token);
    //                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();
    //                    return axios(originalRequest);
    //                }
    //            })
    //    }
    return Promise.reject(error);
});