import React from 'react'
import { getkeyvalueText, retrievePrivacyLink } from '../../util/helperfunctions'
import { MetaText, InlineLink } from '@hm-group/fabric-components'

export const PrivacyLink = ({ corporateBrandId, countryCode, lslKeyValue }) => {
  const handlePrivacyAction = (brandId, countryCode) => {
    window.open(retrievePrivacyLink(brandId, countryCode, lslKeyValue), '_blank')
  }
  const isshowkey = false
  return (
    <>
      <InlineLink
        className="subscription-disclaimer"
        token="meta"
        target="_blank"
        data-testid="request_access_link"
        rel="noreferrer"
        onClick={() => {
          handlePrivacyAction(corporateBrandId, countryCode, lslKeyValue)
        }}
        onKeyDown={(event) => {
          handlePrivacyAction(corporateBrandId, countryCode, lslKeyValue)
        }}
      >
        {getkeyvalueText('LandingPage', 'privacynoticeLabel', isshowkey, lslKeyValue.lslKeyData)}
      </InlineLink>
      <MetaText className="subscription-disclaimer">
        {
          getkeyvalueText('editSubscriptions', 'emailsubsadditionalInfo', isshowkey, lslKeyValue.lslKeyData).split(
            '{privacylink}',
          )[1]
        }
      </MetaText>
    </>
  )
}
