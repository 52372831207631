import React, {useState, useContext } from 'react';
import './Error.scss';
import axios from 'axios';
import * as qs from 'query-string';
import { getkeyvalueText, identifyEvent } from '../../util/helperfunctions';
import { useHistory, useLocation } from 'react-router-dom';
import { InlineLink, BodyText,HeadingLarge, ListGrid } from '@hm-group/fabric-components'
import { UserContext } from '../../context/userContext';

export const Error = ({ user, userName }) => {

    const isApLoginUrlExpired = useLocation().state && useLocation().state.isApLoginUrlExpired;
    let history = useHistory();
    const [isshowkey, setisshowkey] =  useState(qs.parse(useLocation().search).showkeys);

    const {lslKeyValue} = useContext(UserContext);

    let isUser = user;
    let isuserName = userName;

    //If Already Logged In, Capture Login Identifier from Session Storage
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn) {
        isUser = true;
    }

    const useridentifier = localStorage.getItem("userName");
    if (useridentifier != null || useridentifier != "") {
        isuserName = useridentifier;
    }

    const handleReturn = () => {
        checkValidUser();
    }

    //If Already Logged Out -> Clear Session Storage
    if (!isUser) {
        localStorage.clear();
        // history.push('/Logoff')
    }

    //If Unable to find customer Email -> Clear Session Storage
    if (isuserName == "" || isuserName == null) {
        localStorage.clear();
        isUser = false;
    }

    const checkValidUser = () => {

        axios.post('/v2/user').then(data => {
            if (data.data == 'valid') {
                //Redirect To Dashboard
                let transport_data = {
                    userName: useridentifier
                }
                localStorage.setItem("isLoggedIn", true);
                history.push({ pathname: '/dashboard', state: transport_data })
            } else if (data.data == 'invalid') {
                //Revoke Session
                localStorage.clear();
                history.push('/Logoff')
            }
        })
            .catch((err) => {
                localStorage.clear();
                history.push('/Logoff')
            })
    }

    return (
      <>
        {isApLoginUrlExpired ? (
          <div>
            <div className="text_section">
              <div className="h2_header">
                <HeadingLarge Tag="h1" className="unavailableLabel">
                  {getkeyvalueText('A&PSessionExpiredPage', 'a&pdataexpiredLabel', isshowkey, lslKeyValue.lslKeyData)}
                </HeadingLarge>
              </div>
              <br />
              <BodyText Tag="p" alignment="center" className="unavailableLabel">
                {getkeyvalueText('A&PSessionExpiredPage', 'a&pdataavailableLabel', isshowkey, lslKeyValue.lslKeyData)}
              </BodyText>
              <br />
              <BodyText Tag="p" alignment="center" className="unavailableLabel">
                {getkeyvalueText('A&PSessionExpiredPage', 'a&pnewcopyLabel', isshowkey, lslKeyValue.lslKeyData)}
              </BodyText>
            </div>
          </div>
        ) : (
          <ListGrid
            numberOfColumns={{
              xsmall: 1,
              small: 1,
              medium: 1,
              xlarge: 1,
            }}
          >
            <div className="logoff_section">
              <div className="brandLogo__title_section">
                <div className="not_found_header">
                  <BodyText Tag="p">
                    {getkeyvalueText('ErrorPage', 'pagenotfoundLabel', isshowkey, lslKeyValue.lslKeyData)}
                  </BodyText>
                </div>
                <div className="redirect__text">
                  <BodyText Tag="p">
                    {getkeyvalueText('ErrorPage', 'pageisunavailableLabel', isshowkey, lslKeyValue.lslKeyData)}
                  </BodyText>
                  <br />
                  {isUser ? (
                    <BodyText Tag="p">
                       {lslKeyValue && lslKeyValue.lslKeyData && getkeyvalueText('ErrorPage', 'returnmpdLabel', isshowkey, lslKeyValue.lslKeyData).replace('{mpdlink}.', '')}
                      <InlineLink
                        className="back2home"
                        onClick={() => {
                            handleReturn()
                          }}
                          onKeyDown={(event) => {
                            identifyEvent(event) && handleReturn()
                          }}
                        >
                          {getkeyvalueText('login', 'mpd_heading', isshowkey, lslKeyValue.lslKeyData)}.
                        </InlineLink>
                      </BodyText>
                    ) : (
                    <BodyText Tag="p">
                      {getkeyvalueText('ErrorPage', 'welcomebckthrgaccountLabel', isshowkey, lslKeyValue.lslKeyData)}
                    </BodyText>
                  )}
                </div>
              </div>
            </div>
          </ListGrid>
        )}
      </>
    )

}


export default Error;