import React from 'react'
import { Modal } from '@hm-group/fabric-components'
import Portal from '@hm-group/fabric-components/helpers/Portal'
import './ModalComponent.scss'

const ModalComponent = ({ children, openModal, onClose, heading }) => {
  return (
    <Portal>
      <Modal
        isOpen={openModal}
        content={children}
        heading={heading}
        closeLabel="Close"
        onClose={(e) => {
          if (e) {
            onClose(false)
          }
        }}
      />
    </Portal>
  )
}
export default ModalComponent
