import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    Fragment,
  } from 'react';
  import propTypes from 'prop-types';
  import { useHistory, useLocation } from 'react-router-dom';
  import moment from 'moment';

import LightBox from '../../components/LightBoxes/LightBox';
  
  const SessionTimeout = ({user , handleSessionTimeout, handleLogout}) => {

    const [events, setEvents] = useState(['click', 'load', 'scroll']);
    const [second, setSecond] = useState(0);
    const [isOpen, setOpen] = useState(false);
    // const {
    //   authState: { true },   
    //   handleClose,
    // } = useAuth();
  
    let timeStamp;
    let warningInactiveInterval = useRef();
    let startTimerInterval = useRef();
    let history = useHistory();

    let isAuthenticated = user;

    //If Already Logged In, Capture Login Identifier from Session Storage
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn) {
        isAuthenticated = true;
    }
  
    // start inactive check
    let timeChecker = () => {
      startTimerInterval.current = setTimeout(() => {
        let storedTimeStamp = localStorage.getItem('lastTimeStamp');
        warningInactive(storedTimeStamp);
      }, 540000);
    };
  
    // warning timer
    let warningInactive = (timeString) => {
      clearTimeout(startTimerInterval.current);
  
      warningInactiveInterval.current = setInterval(() => {
        const maxTime = 10;
        const popTime = 9;
  
        const diff = moment.duration(moment().diff(moment(timeString)));
        const minPast = diff.minutes();
        const leftSecond = 60 - diff.seconds();
  
        if (minPast === popTime) {
          setSecond(leftSecond);
          setOpen(true);
        }
  
        if (minPast === maxTime) {
          clearInterval(warningInactiveInterval.current);
          setOpen(false);
          localStorage.removeItem('lastTimeStamp');
          handleLogout();
          localStorage.clear();
        //   handleSessionTimeout();
          history.push('/Logoff',{state : {"logOut" : true}});
        }
      }, 1000);
    };
  
    // reset interval timer
    let resetTimer = useCallback(() => {
      clearTimeout(startTimerInterval.current);
      clearInterval(warningInactiveInterval.current);
  
      if (isAuthenticated) {
        timeStamp = moment();
        localStorage.setItem('lastTimeStamp', timeStamp);
      } else {
        clearInterval(warningInactiveInterval.current);
        localStorage.removeItem('lastTimeStamp');
      }
      timeChecker();
      setOpen(false);
    }, [isAuthenticated]);
  
    // handle close popup
            
    const handleClose = (e) => {
        if (e != undefined) {
            e.preventDefault();
            setOpen(false);
            resetTimer();
        }
    };

     //LogOff Functionality
     const handle__CTA_action = () => {
      setOpen(false);
      window.opener = null;
      window.open('', '_self');
      window.close();
      localStorage.clear();
      handleLogout();
      history.push('/Logoff',{state : {"logOut" : true}});
  }
  
    useEffect(() => {
      events.forEach((event) => {
        window.addEventListener(event, resetTimer);
      });
  
      timeChecker();
  
      return () => {
        clearTimeout(startTimerInterval.current);
        //   resetTimer();
      };
    }, [resetTimer, events, timeChecker]);
  
  
    if (!isOpen) {
      return null;
    }
  
    // change fragment to modal and handleclose func to close
    return <LightBox open={isOpen} handle__CTA_action={handle__CTA_action} handleClose={handleClose} type={"session_timeout_warning"} commonRedirect={handleClose}/>;
  };
  
  SessionTimeout.propTypes = {
    user: propTypes.bool,
    handleSessionTimeout : propTypes.func,
    handleLogout : propTypes.func
  }

  export default SessionTimeout;