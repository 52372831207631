import React,{useEffect, useState, useContext} from 'react';
import './Logoff.scss';
import propTypes from 'prop-types';
import * as qs from 'query-string';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { getkeyvalueText } from '../../util/helperfunctions';
import { BodyText } from '@hm-group/fabric-components'
import { UserContext } from '../../context/userContext';

export const LogOff = ({user,userName}) => {

    // const logOut = useLocation().state?.logOut;
    let history = useHistory();
    const {lslKeyValue} = useContext(UserContext);

    let isUser = user;
    let isuserName = userName;

    const [isshowkey, setisshowkey] =  useState(qs.parse(useLocation().search).showkeys);

    //If Already Logged In, Capture Login Identifier from Session Storage
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn) {
        isUser = true;
    }

    const useridentifier = localStorage.getItem("userName");
    if (useridentifier != null || useridentifier != "") {
        isuserName = useridentifier;
    }

    //If Unable to find customer Email -> Clear Session Storage
    if (isuserName == "" || isuserName == null) {
        localStorage.clear();
        localStorage.removeItem("lastTimeStamp");
        isUser = false
    }

    //If Already Logged in -> Redirect to Dashboard
    if (isUser) {
        let transport_data = {
            userName : isuserName
        }
        history.push({ pathname: '/dashboard', state: transport_data})
    }

    //If Already Logged Out -> Clear Session Storage
    if(!isUser) {
        localStorage.clear();
        localStorage.removeItem("lastTimeStamp");
    }

    useEffect(()=>{

        const isvtagPresent = localStorage.getItem("vtag");
        const username = localStorage.getItem("userName");
    
        const headers = {
          'Authorization': isvtagPresent,
          'username' : username
        }
    
        axios.post('/v2/user').then(data=>{
                  if (data.data == 'valid') {
                    //Redirect To Dashboard
                    let transport_data = {
                        userName : useridentifier
                    }
                    localStorage.setItem("isLoggedIn", true);
                    history.push({ pathname: '/dashboard', state: transport_data})
                  } else if (data.data == 'invalid') {
                    //Revoke Session
                    axios.post('/userlogged').then(data=>{
                        localStorage.clear();
                        history.push('/Logoff')
                    })
                  }
                })
                .catch((err)=> {
                    axios.post('/userlogged').then(data=>{
                        localStorage.clear();
                        history.push('/Logoff')
                    })
                })
      },[])

    return (
        <div className="logoff_section">
            <div className="brandLogo__title_section">
                {/* <div className="brand__img">
                        <img className="brand_puff_logo" src={getBrandImagebyName("HMGroup")} alt="H&M Group"></img>
                    </div> */}

                <div>
                    <BodyText Tag="p" className="logoffheader">{getkeyvalueText('login','mpd_heading', isshowkey, lslKeyValue.lslKeyData)}</BodyText>
                </div>
                
                <div>
                    <BodyText Tag="p" className="log_off_header">{getkeyvalueText('Logoff','loggedoutHeaderLabel', isshowkey, lslKeyValue.lslKeyData)}</BodyText>
                </div>
                <div className="logoff__text">
                    <BodyText Tag="p">
                        {getkeyvalueText('Logoff','loggedoutfrmmpdLabel', isshowkey, lslKeyValue.lslKeyData)}
                    </BodyText>
                    {/* <br/> */}
                    <BodyText Tag="p">
                        {getkeyvalueText('Logoff','closewindowLabel', isshowkey, lslKeyValue.lslKeyData)}
                    </BodyText>
                </div>
            </div>
        </div>
    );

}

LogOff.propTypes = {
    user: propTypes.bool,
    userName : propTypes.string
  }
