import AppBar from './components/AppBar'
import React, { Component, useState, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import { BodyText, InlineLink,ListGrid } from '@hm-group/fabric-components'
import HMGrpLogo from '../../assets/images/hm_group_logo.svg'
import LightBox from '../LightBoxes/LightBox';
import propTypes from 'prop-types';
import * as qs from 'query-string';
import './Header.scss'
import { getkeyvalueText, identifyEvent } from '../../util/helperfunctions';
import { UserContext } from '../../context/userContext';




const Header = ({ handleLogout, user}) => {

  const {lslKeyValue} = useContext(UserContext);
    const [isOpen, setisOpen] = useState(false);
    const search = useLocation().pathname;
    const searchParams = useLocation().search;
    const searchState = useLocation().state;
    const userName = useLocation().state && useLocation().state.userName;
    const currentBrand = useLocation().state && useLocation().state.currbrand;
    const currentCntry = useLocation().state && useLocation().state.currcountry;
    const [isshowkey, setisshowkey] =  useState(qs.parse(useLocation().search).showkeys);
    let history = useHistory();
    let searchStringSplit = (search.toLowerCase()).split("/")[1];
    let searchStringSplit2 = (search.toLowerCase()).split("/")[2];
    let islogoff = searchStringSplit == "logoff" ? true : false;
    let isLoginBox = (searchStringSplit == "login" || searchStringSplit == "aplogin") || searchStringSplit == "" ? true : false;
    let isErrorPage = (searchStringSplit == "errorpage") || searchStringSplit == "" ? true : false;
    let isResetPage = (searchStringSplit == "password") || searchStringSplit == "" ? true : false;
    let isHealthPage = (searchStringSplit == "actuator") || searchStringSplit == "" ? true : false;
    let isPlannedOutagePage = (searchStringSplit2 == "plannedoutage") || searchStringSplit2 == "" ? true : false;
    
    let transport_data = {
        userName : userName,
        currbrand: currentBrand,
        currcountry: currentCntry
    }

    const usePathname = () => {
        const location = useLocation();
        const isLogin = location.pathname.split("/")[1] == "login" ? true : false;
        return isLogin;
    }

    //If Already Logged In, Capture Login Identifier from Session Storage
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    let isUser = user;
    if (isLoggedIn) {
        isUser = true;
    }

    //PopUp open Logic
    const handleClose = () => {
        setisOpen(!isOpen);
        // history.push('/dashboard')
    }

    //LogOff Functionality
    const handle__CTA_action = () => {
        setisOpen(!isOpen);
        window.opener = null;
        window.open('', '_self');
        window.close();
        localStorage.clear();
        handleLogout();
        history.push('/Logoff')
    }

    const commonRedirect = () => {
        setisOpen(!isOpen);
        history.push({ pathname: search, search: searchParams, state: searchState })
    }

    const handleAnchorClick = () => {
      if (history.location.pathname !== '/dashboard') {
        history.push({ pathname: '/dashboard', state: transport_data })
      }
    }

    return (
      <React.Fragment>
        {(islogoff || isErrorPage || isLoginBox || isResetPage || isHealthPage) ? (
          <ListGrid
            numberOfColumns={{
              xsmall: 1,
              small: 1,
              medium: 1,
              xlarge: 1,
            }}
          >
            <div className="header-logo header-login-align centrealign">
              <span>
                <InlineLink className="hm__grp_anchor" tabIndex={0}>
                  <img className="hm__grp_logo_img" src={HMGrpLogo} alt="H&M Group"></img>
                  <span className="hide-content notranslate">H&M Hennes & Mauritz</span>
                </InlineLink>
              </span>
            </div>
          </ListGrid>
        ) : !isPlannedOutagePage ? (
          <ListGrid
            numberOfColumns={{
              xsmall: 1,
              small: 1,
              medium: 1,
              xlarge: 1,
            }}
          >
            <div>
              <Hidden lgUp smUp>
                <AppBar
                  isLogin={usePathname()}
                  isshowkey={isshowkey}
                  handleClose={handleClose}
                  user={user}
                  userName={userName}
                />
              </Hidden>
              <Hidden xsDown>
                <div className="header-logo">
                  <span>
                    <InlineLink
                      className="hm__grp_anchor"
                      onClick={handleAnchorClick}
                      onKeyDown={(event) => {
                        identifyEvent(event) && handleAnchorClick()
                      }}
                      tabIndex={0}
                    >
                      <img className="hm__grp_logo_img" src={HMGrpLogo} alt="H&M Group"></img>
                      <span className="hide-content notranslate">H&M Hennes & Mauritz</span>
                    </InlineLink>
                  </span>
                  {!(islogoff || isLoginBox || isErrorPage) ? (
                    <div
                      className="hm__avatar"
                      onClick={handleClose}
                      onKeyDown={(event) => {
                        identifyEvent(event) && handleClose()
                      }}
                      tabIndex={0}
                    >
                      <InlineLink
                        className="menu__myhm_icon"
                        data-remodal-trigger="club-signin"
                        data-signin-state="guest"
                      >
                        <span className="hide-content notranslate">Sign In</span>
                      </InlineLink>
                      <BodyText Tag="p">
                        {getkeyvalueText('LandingPage', 'logoutbtnLabel', isshowkey, lslKeyValue.lslKeyData)}
                      </BodyText>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </Hidden>
              <LightBox
                open={isOpen}
                handleClose={handleClose}
                commonRedirect={commonRedirect}
                type={'notify_logOff'}
                handle__CTA_action={handle__CTA_action}
                userName={userName}
                isshowkey={isshowkey}
              />
            </div>
          </ListGrid>
        ) : (
          ''
        )}
      </React.Fragment>
    )
}

Header.propTypes = {
    handleLogout: propTypes.func,
    user: propTypes.bool
}

export default Header;