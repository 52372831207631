import React, { createContext, useState, useEffect } from 'react';
import SessionStorageService from '../Services/SessionStorageService';

// Create Context
const CustomerDataContext = createContext();

// Initial customer data object with empty strings
const initialCustomerData = {
    businessPartnerId: "",
    countryCode: "",
    corporateBrandId: "",
    guestBusinessPartnerId: "",
    guestAPRequestDate: "",
    guestCreationDate: "",
    guestCICFlag: "",
    guestAPRequestStatus: "",
    guestAPCaseIdentifier: "",
    guestAPDownloadDataExpiresOn: "",
    guestCICDate: ""
};

// Create Provider component
const CustomerDataProvider = ({ children }) => {
    const [customerData, setCustomerData] = useState(() => {
        const savedData = SessionStorageService.getItem('customerData');
        return savedData ? JSON.parse(savedData) : {initialCustomerData}; 
    });

    useEffect(() => {
      
        SessionStorageService.setItem('customerData', JSON.stringify(customerData));
    }, [customerData]);

    const updateCustomerData = (newDetails) => {
        setCustomerData((prevCustomer) => ({
            ...prevCustomer,
            ...newDetails,
        }));
    };

    return (
        <CustomerDataContext.Provider value={{ customerData, updateCustomerData }}>
            {children}
        </CustomerDataContext.Provider>
    );
};

export { CustomerDataContext, CustomerDataProvider };
