import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        primary: {
          main: '#222',
        },
        secondary: {
            main : '#faf9f8'
        }
      },
    Label : {
        // fontFamily : ['HM Sans Regular']
    },
    Link : {
        // fontFamily : ['HM Sans Regular']
       },
    button : {
        // fontFamily : ['HM Sans Regular']
    },
    typography: {
        // h2: 'HM Sans Semi Bold',
        // body1 : 'HM Sans Regular',
        },
    
    
      
})

export default theme;