import React, { useState, createContext } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router-dom'
import LandingPage from '../components/LandingPage'
import Header from '../components/Header'
import MyAccountServices from '../components/PersonalDataSection'
import EditSubscriptions from '../components/EditSubscription'
import ManageServices from '../components/ManageServices'
import ProtectedRoute from '../components/ProtectedRoutes'
import SessionTimeout from '../util/SessionTimeout'
import { HeadingXSmall } from '@hm-group/fabric-components'
import { AnalyticsContextProvider } from '../context/analyticsContext'
import DownloadPersonalData from '../components/PersonalDataSection/components/DownloadPersonalData'
import { DeletePersonalData } from '../components/CIC/DeletePersonalData'
import { CustomerProvider } from '../context/customerDetailsContext'
import { CustomerDataProvider } from '../context/customerDataContext'
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary'
import OtpLogin from '../components/Login/OTPLogin/OtpLogin'
import { LogOff } from '../components/LogOff/Logoff'
import { Error } from '../components/404/Error'
import { UserProvider } from '../context/userContext'

const Routes = () => {
  const [user, setUser] = useState(true)
  const [username, setusername] = useState('')
  const [accordianExpanded, setAccordianExpanded] = useState({
    subscriptionAccord: false,
    accessDataAccord: false,
    deleteAccountAccord: false,
  })
  const search = useLocation().pathname
  let searchStringSplit = search.toLowerCase().split('/')[1]
  let islogoff = searchStringSplit == 'logoff' ? true : false
  let isLoginBox = searchStringSplit == 'login' || searchStringSplit == '' ? true : false
  let isErrorPage = searchStringSplit == 'errorpage' || searchStringSplit == '' ? true : false
  let isHealthPage = searchStringSplit == 'actuator' || searchStringSplit == '' ? true : false

  let history = useHistory()

  //Logic For InMemory Login Identifier, to be set TRUE when User is Successfully Logged IN
  const handleLogin = (userName) => {
    setUser(true)
    setusername(userName)
  }

  //Logic For InMemory LogOut Identifier, to be set TRUE when User is Successfully Logged OUT
  const handleLogout = (e) => {
    setUser(false)
  }

  //Logic For Handling Session Timeout and Logging Out the USER
  const handleSessionTimeout = (e) => {
    history.push('/Logoff')
  }

  const handleToggleAccordian = (accordianName) => {
    if (accordianName === 'reset') {
      setAccordianExpanded({ subscriptionAccord: false, accessDataAccord: false, deleteAccountAccord: false })
    } else {
      setAccordianExpanded((prevState) => ({
        ...prevState,
        [accordianName]: !prevState[accordianName],
      }))
    }
  }

  return (
    <React.Fragment>
      <ErrorBoundary>
        <UserProvider>
          <AnalyticsContextProvider>
            <Header handleLogout={handleLogout} user={user} />
            <Switch>
              <CustomerDataProvider>
                <CustomerProvider>
                  <Route exact path="/login" render={(props) => <OtpLogin {...props} handleLogin={handleLogin} />} />
                  <Route exact path="/aplogin" render={(props) => <OtpLogin {...props} handleLogin={handleLogin} />} />
                  <Route
                    exact
                    path="/Logoff"
                    render={(props) => <LogOff {...props} user={user} username={username} />}
                  />
                  <Route
                    exact
                    path="/errorPage"
                    render={(props) => <Error {...props} user={user} username={username} />}
                  />
                  <Route exact path="/actuator/reactapp/health">
                    <HeadingXSmall Tag="h1">Hey There!!! The App is Healthy</HeadingXSmall>
                  </Route>

                  <ProtectedRoute
                    exact
                    path="/dashboard"
                    handleToggleAccordian={handleToggleAccordian}
                    user={user}
                    username={username}
                    component={LandingPage}
                  />
                  <ProtectedRoute
                    exact
                    path="/editSubscriptions"
                    user={user}
                    username={username}
                    component={EditSubscriptions}
                  />
                  <ProtectedRoute
                    exact
                    path="/deletePersonalData"
                    user={user}
                    username={username}
                    component={DeletePersonalData}
                  />
                  <ProtectedRoute
                    exact
                    path="/accessPersonalData"
                    user={user}
                    username={username}
                    component={MyAccountServices}
                  />
                  <ProtectedRoute
                    exact
                    path="/downloadPersonalData"
                    user={user}
                    username={username}
                    component={DownloadPersonalData}
                  />
                  <ProtectedRoute
                    exact
                    path="/ManageServices"
                    accordianExpanded={accordianExpanded}
                    handleToggleAccordian={handleToggleAccordian}
                    user={user}
                    username={username}
                    component={ManageServices}
                  />
                </CustomerProvider>
              </CustomerDataProvider>
              <Redirect to="/errorPage" />
            </Switch>

            {!(islogoff || isLoginBox || isErrorPage || isHealthPage) ? (
              <SessionTimeout user={user} handleSessionTimeout={handleSessionTimeout} handleLogout={handleLogout} />
            ) : (
              ''
            )}
          </AnalyticsContextProvider>
        </UserProvider>
      </ErrorBoundary>
    </React.Fragment>
  )
}

export default Routes
