import React, { useState, createContext,useEffect } from 'react'
import { sendAnalytics } from '../Services/DataService'
import SessionStorageService from '../Services/SessionStorageService'

const AnalyticsContext = createContext({
  analyticsData: undefined,
  setAnalyticsData: () => {},
})

const AnalyticsContextProvider = ({ children }) => {
  const [analyticsData, setAnalyticsData] = useState(() => {
    const savedData = SessionStorageService.getItem('analyticsData')
    return JSON.parse(savedData)
  })

  useEffect(() => {
    SessionStorageService.setItem('analyticsData', JSON.stringify(analyticsData))
  }, [analyticsData])


  const updateAnalyticsData = (data) => {
    setAnalyticsData(data)
    sendAnalyticsData(data)
  }

  const sendAnalyticsData = async (data) => {
    try {
      await sendAnalytics({ ...data })
      // addd if else to handle api error
    } catch (e) {
      // handle error
    }
  }

  return (
    <AnalyticsContext.Provider value={{ analyticsData, setAnalyticsData, updateAnalyticsData }}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export { AnalyticsContext, AnalyticsContextProvider }
