import React from "react";
import { getBrandImagebyID, getCountryNamebyCode, getDisplayBrandNamebyID, getkeyvalueText } from "../../../util/helperfunctions";
import { HeadingLarge } from "@hm-group/fabric-components";
import { brandNameFromID } from "../../../util/constants";


export const SubscriptionHeader = ({corporateBrandId,lslKeyValue,countryCode}) => {

    return (
        <div className="brandLogo__title_section">
          <div className="brand__img">
            <img
              className="brand_puff_logo_manage_page"
              src={getBrandImagebyID(corporateBrandId)}
              alt={brandNameFromID[corporateBrandId]}
            />
          </div>
          <div className="edit__text" data-testid="edit_text">
            <HeadingLarge Tag="h1" className="edit__div">
              {getkeyvalueText('editSubscriptions', 'editsubswthbrandLabel', false, lslKeyValue.lslKeyData)
                .replace('{Brand}', `${getDisplayBrandNamebyID(corporateBrandId)}`)
                .replace('{Market}', `${getCountryNamebyCode(countryCode, false, lslKeyValue.lslKeyData)}`)}
            </HeadingLarge>
          </div>
        </div>
    )
}