import React, { useContext } from 'react'
import {
  getkeyvalueText,
} from '../../../util/helperfunctions'
import { GetCSEmailHtml } from '../../../util/helperfunctions'
import { BodyText } from '@hm-group/fabric-components'

import moment from 'moment'
import { CustomerContext } from '../../../context/customerDetailsContext'
import { CustomerDataContext } from '../../../context/customerDataContext'
import { brandNameFromID } from '../../../util/constants'
import { UserContext } from '../../../context/userContext'

const NotifyAccessText = ({isGuestInfoClicked }) => {
  const {lslKeyValue} = useContext(UserContext)
  const { customer } = useContext(CustomerContext)
  const {customerData} = useContext(CustomerDataContext)
  const { corporateBrandId, countryCode, APRequestDate } = customer
  const {
    guestAPRequestDate,
  } = customerData;

  const isshowkey = false // check this later

  const momentDate = isGuestInfoClicked ? guestAPRequestDate : APRequestDate

  const formattedDate = moment(momentDate).format('DD MMMM YYYY')
  const monthName = moment(momentDate).format('MMMM')
  const monthKey = getkeyvalueText('Month', monthName, isshowkey, lslKeyValue.lslKeyData)

  const formattedDateWithMonth = formattedDate.replace(monthName, monthKey).trim()

  const copyDataRequestedLabel = getkeyvalueText(
    'ManageServices',
    'copydatareqeustedLabel',
    isshowkey,
    lslKeyValue.lslKeyData,
  )
  const [beforeDate, afterDate] = copyDataRequestedLabel.split('{dd mmm yyyy}')

  return (
    <div>
      <BodyText Tag="p">
        {beforeDate}
        {formattedDateWithMonth}
        {afterDate?.trim()}
      </BodyText>
      <BodyText Tag="p">
        {getkeyvalueText('Lightboxes', 'sendbyemailin30daysLabel', isshowkey, lslKeyValue.lslKeyData)}
      </BodyText>
      <BodyText Tag="p">
        {getkeyvalueText('Lightboxes', 'contactCStype1Label', isshowkey, lslKeyValue.lslKeyData)}
      </BodyText>
      <GetCSEmailHtml
        brand={brandNameFromID[corporateBrandId]}
        country={countryCode}
        lslKeyData={lslKeyValue.lslKeyData}
        isshowkey={isshowkey}
        align={'left'}
      />
    </div>
  )
}


export default NotifyAccessText
