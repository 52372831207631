export const CountryCode = [
    {
        "countryCode": "AD",
        "countryName": "Andorra",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "AR",
        "countryName": "Argentina",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "AS",
        "countryName": "SamoaAmerica",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "AT",
        "countryName": "Austria",
        "ConsentedAge": "16",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "AU",
        "countryName": "Australia",
        "ConsentedAge": "18",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '18'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "BB",
        "countryName": "Barbados",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "BD",
        "countryName": "Bangladesh",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "BE",
        "countryName": "Belgium",
        "ConsentedAge": "16",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "BG",
        "countryName": "Bulgaria",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '18'}
        ],
        "ConsentedAge": "18",
         "emaillang" : "EN",
    },
    {
        "countryCode": "BH",
        "countryName": "Bahrain",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "BN",
        "countryName": "BruneiDarussalam",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "BT",
        "countryName": "Bhutan",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "CA",
        "countryName": "Canada",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
        "ConsentedAge": "16",
         "emaillang" : "EN",
    },
    {
        "countryCode": "CH",
        "countryName": "Switzerland",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
        "ConsentedAge": "16",
         "emaillang" : "EN",
    },
    {
        "countryCode": "CL",
        "countryName": "Chile",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "CN",
        "countryName": "China",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
        "emaillang" : "EN",
    },
    {
        "countryCode": "CK",
        "countryName": "CookIslands",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
        "ConsentedAge": "16",
         "emaillang" : "EN",
    },
    {
        "countryCode": "CR",
        "countryName": "CostaRica",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "CY",
        "countryName": "Cyprus",
        "ConsentedAge": "16",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "CZ",
        "countryName": "CzechRepublic",
        "ConsentedAge": "16",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "DE",
        "countryName": "Germany",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
        "ConsentedAge": "16",
         "emaillang" : "DE",
    },
    {
        "countryCode": "DK",
        "countryName": "Denmark",
        "ConsentedAge": "15",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '15'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "EC",
        "countryName": "Ecuador",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "EE",
        "countryName": "Estonia",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
        "emaillang" : "EN",
    },
    {
        "countryCode": "TO",
        "countryName": "Tonga",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
        "ConsentedAge": "16",
         "emaillang" : "EN",
    },
    {
        "countryCode": "ES",
        "countryName": "Spain",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '18'}
        ],
        "emaillang" : "EN",
    },
    {
        "countryCode": "KN",
        "countryName": "SaintKitts&Nevis",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '18'}
        ],
        "emaillang" : "EN",
    },
    {
        "countryCode": "SR",
        "countryName": "Suriname",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '18'}
        ],
        "ConsentedAge": "18",
         "emaillang" : "EN",
    },
    {
        "countryCode": "FI",
        "countryName": "Finland",
        "ConsentedAge": "15",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '15'}
        ],
        "emaillang" : "EN",
    },
    {
        "countryCode": "FJ",
        "countryName": "Fiji",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "FM",
        "countryName": "Micronesia",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "FO",
        "countryName": "FaroeIslands",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "FR",
        "countryName": "France",
        "ConsentedAge": "16",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "GB",
        "countryName": "UnitedKingdom",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
        "ConsentedAge": "16",
         "emaillang" : "EN",
    },
    {
        "countryCode": "GE",
        "countryName": "Georgia",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "GG",
        "countryName": "Guernsey",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "GI",
        "countryName": "Gibraltar",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "GL",
        "countryName": "Greenland",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "GR",
        "countryName": "Greece",
        "ConsentedAge": "16",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "GT",
        "countryName": "Guatemala",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "GU",
        "countryName": "Guam",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "HN",
        "countryName": "Honduras",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "HR",
        "countryName": "Croatia",
        "ConsentedAge": "16",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "HU",
        "countryName": "Hungary",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
        "ConsentedAge": "16",
         "emaillang" : "EN",
    },
    {
        "countryCode": "ID",
        "countryName": "Indonesia",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "IE",
        "countryName": "Ireland",
        "ConsentedAge": "16",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "IL",
        "countryName": "Israel",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "IN",
        "countryName": "India",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '18'}
        ],
        "ConsentedAge": "18",
         "emaillang" : "EN",
    },
    {
        "countryCode": "IS",
        "countryName": "Iceland",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "IT",
        "countryName": "Italy",
        "ConsentedAge": "16",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "JE",
        "countryName": "Jersey",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "JM",
        "countryName": "Jamaica",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "JP",
        "countryName": "Japan",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "KE",
        "countryName": "Kenya",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
        "emaillang" : "EN",
    },
    {
        "countryCode": "KI",
        "countryName": "Kiribati",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "KH",
        "countryName": "Cambodia",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "KR",
        "countryName": "SouthKorea",
        "ConsentedAge": "16",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "LI",
        "countryName": "Liechtenstein",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "LT",
        "countryName": "Lithuania",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "LU",
        "countryName": "Luxembourg",
        "ConsentedAge": "16",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "LV",
        "countryName": "Latvia",
        "ConsentedAge": "16",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "MA",
        "countryName": "Morocco",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "MH",
        "countryName": "MarshallIslands",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "MN",
        "countryName": "Mongolia",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "MP",
        "countryName": "NorthernMarianaIslands",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "MV",
        "countryName": "Maldives",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "MX",
        "countryName": "Mexico",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "MY",
        "countryName": "Malaysia",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "NC",
        "countryName": "NewCaledonia",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "NG",
        "countryName": "Nigeria",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "NL",
        "countryName": "Netherlands",
        "ConsentedAge": "16",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "NO",
        "countryName": "Norway",
        "ConsentedAge": "15",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '15'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "NZ",
        "countryName": "NewZealand",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "PE",
        "countryName": "Peru",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "PF",
        "countryName": "FrenchPolynesia",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "PH",
        "countryName": "Philippines",
        "ConsentedAge": "16",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "PL",
        "countryName": "Poland",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '18'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '18'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "PR",
        "countryName": "PuertoRico",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "PT",
        "countryName": "Portugal",
        "ConsentedAge": "18",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '18'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "PW",
        "countryName": "Palau",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "RO",
        "countryName": "Romania",
        "ConsentedAge": "16",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "RS",
        "countryName": "Serbia",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "RU",
        "countryName": "Russia",
        "ConsentedAge": "16",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "SA",
        "countryName": "Saudi Arabia",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "SE",
        "countryName": "Sweden",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
        "emaillang": "SV",
    },
    {
        "countryCode": "SG",
        "countryName": "Singapore",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "SI",
        "countryName": "Slovenia",
        "ConsentedAge": "16",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "SK",
        "countryName": "Slovakia",
        "ConsentedAge": "16",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "SM",
        "countryName": "SanMarino",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "SV",
        "countryName": "ElSalvador",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "TH",
        "countryName": "Thailand",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "TR",
        "countryName": "Turkey",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "TW",
        "countryName": "Taiwan",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "US",
        "countryName": "USA",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '13'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '13'}
        ],
        "emaillang" : "EN",
    },
    {
        "countryCode": "AE",
        "countryName": "UnitedArabEmirates",
        "ConsentedAge": "13",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "UY",
        "countryName": "Uruguay",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "VN",
        "countryName": "Vietnam",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "WF",
        "countryName": "Wallis&Futuna",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "WS",
        "countryName": "Samoa",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "WW",
        "countryName": "Global",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "ZA",
        "countryName": "SouthAfrica",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
        "emaillang" : "EN",
    },
    {
        "countryCode": "KR",
        "countryName": "SouthKorea",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '18'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
        "emaillang" : "EN",
    },
    {
        "countryCode": "SB",
        "countryName": "SolomonIslands",
        "ConsentedAge": "",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "HK",
        "countryName": "HongKong",
        "ConsentedAge": "16",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    },
    {
        "countryCode": "MO",
        "countryName": "Macau",
        "ConsentedAge": "16",
        "ageByBrands": [
            {'brandId': '6', 'brandName': 'ARKET', 'brandAge': '16'},
            {'brandId': '2','brandName': 'MONKI', 'brandAge': '16'},
            {'brandId': '3','brandName': 'WEEKDAY', 'brandAge': '16'},
            {'brandId': '1','brandName': 'COS', 'brandAge': '16'},
            {'brandId': '5','brandName': 'STORIES', 'brandAge': '16'},
            {'brandId': '0', 'brandName': 'HM', 'brandAge': '16'}
        ],
         "emaillang" : "EN",
    }
]
