import { b2cPolicies } from './policies';
import { apiConfig } from './apiConfig';
import { LogLevel } from "@azure/msal-browser";


export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
  },
  cache: {
      cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
            return;
        }
        switch (level) {
          case LogLevel.Error:
              console.error(message);
              return;
          case LogLevel.Info:
              console.info(message);
              return;
          case LogLevel.Verbose:
              console.debug(message);
              return;
          case LogLevel.Warning:
              console.warn(message);
              return;
          }
        }
      }
}
  };
  
export const loginRequest = {
  scopes: ["openid", ...apiConfig.b2cScopes],
  state: ["" + "|disable_signup"],
  prompt: "login",
  loginHint : ""
};

/**
 * Scopes you add here will be used to request a token from Azure AD B2C to be used for accessing a protected resource.
 * To learn more about how to work with scopes and resources, see: 
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const tokenRequest = {
  scopes: [...apiConfig.b2cScopes],   // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};