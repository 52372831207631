import React, { createContext, useContext, useState, useEffect } from 'react';
import * as qs from 'query-string';
import { useHistory } from 'react-router-dom';
import { getLSLData } from '../Services/DataService';
import { retrieveCountry } from '../Services/LoginService';
import { extractAttributeValueFromParams, getkeyvalueText, preprocessData } from '../util/helperfunctions';
import SessionStorageService from '../Services/SessionStorageService';

// Create the UserContext
const UserContext = createContext();


// Create the provider component
 const UserProvider = ({ children }) => {
  const [lslKeyValue, setLslKeyValue] = useState({});
  const history = useHistory();
  const queryParams = qs.parse(location.search);
  const data = {
    cipherUrlParam: decodeURIComponent(queryParams.data),
    doVerify: true,
  };

  // Function to update the user context
  const updateLslKeyValue = (newData) => {
    setLslKeyValue((prevState) => ({
      ...prevState,
      ...newData,
    }));
  };

  // Effect to set the document title based on user data
  useEffect(() => {
    if (lslKeyValue && lslKeyValue.lslKeyData) {
      document.title = getkeyvalueText('login', 'mpd_heading', false, lslKeyValue.lslKeyData);
    }
  }, [lslKeyValue]);

  // Effect to fetch and set the user data
  useEffect(() => {
    const fetchData = async () => {
      const temp = SessionStorageService.getItem('user');
      if (temp && !(Object.keys(queryParams).length !== 0 && !queryParams.code)) {
        setLslKeyValue(temp);
      } else if (Object.keys(queryParams).length !== 0 && !queryParams.code) {
        try {
          const unifiedResponse = await retrieveCountry(data);
          const finalEmailParam = extractAttributeValueFromParams(unifiedResponse.data.urlParam, 'emailid');
          const parsedData = qs.parse(unifiedResponse.data.urlParam);

          parsedData.urlExpired = unifiedResponse.data.urlExpired;
          parsedData.verifiedCustomer = unifiedResponse.data.verifiedCustomer;

          const apiPostData = {
            brandId: parsedData.brandid,
            countryCode: parsedData.countrycode,
            preferredLanguage: parsedData.language,
            version: '3.2',
          };

          try {
            const lslResponse = await getLSLData(apiPostData);
            const finalResponse = {
              lslKeyData: preprocessData(lslResponse.data.data),
              plainres: {
                ...parsedData,
                emailid: finalEmailParam,
                vtag: unifiedResponse.data.vtag,
              },
              preferredLanguage: parsedData.language,
            }

            setLslKeyValue(finalResponse);
            SessionStorageService.setItem('user', finalResponse);
          } catch (error) {
            console.error('Error fetching LSL data:', error);
            localStorage.clear();
            sessionStorage.clear();
            history.push('/Logoff');
          }
        } catch (error) {
          console.error('Error retrieving country:', error);
          localStorage.clear();
          sessionStorage.clear();
          history.push('/Logoff');
        }
      } else {
        history.push('/Logoff');
        SessionStorageService.clear();
      }
    };

    fetchData();
  }, []);

  return (
    <UserContext.Provider value={{ lslKeyValue, updateLslKeyValue }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
