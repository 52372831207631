import React from 'react'
import { getkeyvalueText } from '../../../util/helperfunctions'
import { PrimaryButton, SecondaryButton, Spinner } from '@hm-group/fabric-components'

export const ActionButtons = ({ handleCancel, handleConfirmAction ,lslKeyValue , showLoader}) => {
  return (
    <div className="update_ctabuttons">
      <PrimaryButton
        className="submit__btn"
        data-testid="submit__btn"
        isDisabled={showLoader ? true : false}
        onClick={handleConfirmAction}
      >
        {getkeyvalueText('editSubscriptions', 'savechngsbtnLabel', false, lslKeyValue.lslKeyData)}
      </PrimaryButton>

      {showLoader ? (
        <div className="loader_area">
          <Spinner color={'#ffff'} />
        </div>
      ) : (
        ''
      )}
      <SecondaryButton onClick={handleCancel} className="cancel__btn" data-testid="cancel__btn">
        {getkeyvalueText('commonKey', 'cancelbtnLabel', false, lslKeyValue.lslKeyData)}
      </SecondaryButton>
    </div>
  )
}
