import React, { useContext } from 'react'
import { getkeyvalueText, GetCSEmailHtml } from '../../../util/helperfunctions'
import { BodyText } from '@hm-group/fabric-components'
import { CustomerContext } from '../../../context/customerDetailsContext'
import { brandNameFromID } from '../../../util/constants'
import { UserContext } from '../../../context/userContext'

const NotifyCustomerService = ({isshowkey }) => {
  const {lslKeyValue} = useContext(UserContext)
  const {customer} = useContext(CustomerContext)

  const {
    corporateBrandId,
    countryCode,
  } = customer;


    return (
      <div>
        <BodyText alignment="center" Tag="p">
          {getkeyvalueText('Lightboxes', 'contactCStype3Label', isshowkey, lslKeyValue.lslKeyData)}
        </BodyText>
        <GetCSEmailHtml
          brand={brandNameFromID[corporateBrandId]}
          country={countryCode}
          lslKeyData={lslKeyValue.lslKeyData}
          isshowkey={isshowkey}
          align={'center'}
        />
        <BodyText alignment="center" Tag="p">
          {getkeyvalueText('Lightboxes', 'happytohelpLabel', isshowkey, lslKeyValue.lslKeyData)}
        </BodyText>
      </div>
    )
  }


export default NotifyCustomerService
