import React, { useEffect,useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './InfoTab.scss'
import { useLocation, useHistory } from 'react-router-dom';
import Infoimg from '../../assets/images/infotabImage.jpg';
import Accordian from './components/Accordian'
import * as qs from 'query-string';
import { BrandArray } from '../../assets/images.js';
import { getkeyvalueText } from '../../util/helperfunctions'
import _ from 'lodash';
import { HeadingLarge, BodyText,HeadingSmall, ListGrid} from '@hm-group/fabric-components';
import { UserContext } from '../../context/userContext.js';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#fff',
    }
}));

const InfoTab = () => {

    const [customerName, setcustomerName] = useState(undefined);
    const [isshowkey, setisshowkey] =  useState(qs.parse(useLocation().search).showkeys);
    let newval = localStorage.getItem("customerIdentifier");
    const {lslKeyValue} = useContext(UserContext);

    useEffect(() => {
        //Capture Customer Identifier from Session Storage
        let Name = "";
        Name = newval;
        setcustomerName(Name)
    }, [newval])

    return (
        <ListGrid
            numberOfColumns={{
                xsmall: 1,
                small: 1,
                medium: 1,
                xlarge: 1
            }}>
        <div className="infotab">
            {/* <Box m={3}/> */}
            <HeadingLarge Tag="h1">{_.capitalize(getkeyvalueText('LandingPage','informationh2Label', isshowkey, lslKeyValue.lslKeyData))}</HeadingLarge>
            <div className="static__content">
            <BodyText Tag="p">{((getkeyvalueText('LandingPage','hiLabel', isshowkey, lslKeyValue.lslKeyData)).split('{Name}')[0]).trim()} {customerName ? " "+customerName : ""}{(getkeyvalueText('LandingPage','hiLabel', isshowkey, lslKeyValue.lslKeyData)).split('{Name}')[1]}</BodyText>
            <BodyText Tag="p">{getkeyvalueText('LandingPage','trustLabel', isshowkey, lslKeyValue.lslKeyData)}</BodyText>
            <BodyText Tag="p">{getkeyvalueText('LandingPage','leadthechangeLabel', isshowkey, lslKeyValue.lslKeyData)}</BodyText>
            </div>
            <div className="img__holder">
                <img className="info_img" src={Infoimg} alt="HM" height={220} style={{ padding: '2% 0%' }}></img>
            </div>
            <div className="accordian__holder">
                <Accordian />
            </div>
            <div className="explore__brands">
            <HeadingSmall Tag="h1" alignment="center" className="h2_brands">{getkeyvalueText('LandingPage','explorebrandsLabel', isshowkey, lslKeyValue.lslKeyData)}</HeadingSmall>
                <div className="img__holder bottomspacing">
                    {BrandArray.map(item =>

                        <div key={item.brandName} className="item-h">
                            <a href={item.properties.brandRedirect} aria-label="H&amp;M" target="_blank" rel="noreferrer"></a>
                            <div className="brand_puff">
                                <a href={item.properties.brandRedirect} aria-label="H&amp;M" target="_blank" rel="noreferrer">
                                    <img src={item.properties.srcImg} alt={item.properties.imgAlt} height={187} ></img>
                                </a>
                            </div>
                            {/* Block Commented as per BTCCCCE-4096 */}
                            {/* <div>
                                <a href={item.properties.brandRedirect} className="testspan" aria-label="H&amp;M" target="_blank" rel="noreferrer">
                                    <span className="w-btn-label">{item.properties.anchorName}</span>
                                    <i className="fal fa-external-link"></i>
                                </a>
                            </div> */}
                            <div>
                                <a href={item.properties.brandRedirect} aria-label="H&amp;M" target="_blank" rel="noreferrer">
                                    <img className="brand_logo" src={item.properties.brandLogo} alt="Explore H&amp;M" />
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
        </ListGrid>
    );

}

export default InfoTab;