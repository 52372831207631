import HMimg from './images/cq5dam.web_.2400.720.3.jpeg'
import HMlogo from './images/hm_logo_white.svg'
import CosImage from './images/cos_latestimg.jpeg'
import CosLogo from './images/cos_new_logo_white.svg'
import WeekDayImage from './images/weekday_latestimg.jpeg'
import WeekDayLogo from './images/weekday_logo_white.svg'
import MonkiImage from './images/cq5dam.web_.2400.720-03-1024x307.jpeg'
import MonkiLogo from './images/monki_logo_white.svg'
import HMHomeImage from './images/cq5dam.web_.2400.720-01-1024x307.jpeg'
import HMGroupLogo from './images/hm_home_logo_white.svg'
import OtherStoriesImage from './images/cq5dam.web_.2400.720-04-1024x307.jpeg'
import OtherStoriesLogo from './images/and_other_stories_logo_white.svg'
import ArketImage from './images/cq5dam.web_.2400.720-05-1024x307.jpeg'
import ArketLogo from './images/arket_logo_white.svg'
import HMlogo_black from './images/hm_logo_black.svg'
import Coslogo_black from './images/cos_new_black_logo.svg'
import monki_logo_black from './images/monki_logo_black.svg'
import and_other_stories_logo_black from './images/and_other_stories_logo_black.svg'
import weekday_logo_black from './images/weekday_logo_black.svg';
import afound_logo_black from './images/afound_logo_black.svg';
import arket_logo_black from './images/arket_logo_black.svg';
import hm_group_logo from './images/hm_group_logo.svg';
import hm_home_black_logo from './images/hm_home_black_logo.svg';



export const BrandArray =
    [
        {

            "brandName": "H&M",
            "properties": {
                "srcImg": HMimg,
                "brandLogo": HMlogo,
                "brandRedirect": "https://www.hm.com/",
                "imgAlt": 'H&M',
                "anchorName": 'To hm.com'
            }
        },
        {
            "brandName": "Cos",
            "properties": {
                "srcImg": CosImage,
                "brandLogo": CosLogo,
                "brandRedirect": "https://www.cosstores.com/",
                "imgAlt": 'COS',
                "anchorName": 'To cosstores.com'
            }
        },
        {
            "brandName": "WeekDay",
            "properties": {
                "srcImg": WeekDayImage,
                "brandLogo": WeekDayLogo,
                "brandRedirect": "https://www.weekday.com/",
                "imgAlt": 'WeekDay',
                "anchorName": 'To weekday.com'
            }
        },
        {
            "brandName": "Monki",
            "properties": {
                "srcImg": MonkiImage,
                "brandLogo": MonkiLogo,
                "brandRedirect": "https://www.monki.com/",
                "imgAlt": 'Monki',
                "anchorName": 'To monki.com'
            }
        },
       
        {
            "brandName": "H&MHome",
            "properties": {
                "srcImg": HMHomeImage,
                "brandLogo": HMGroupLogo,
                "brandRedirect": "https://www.hm.com/",
                "imgAlt": 'H&MHome',
                "anchorName": 'To H&M HOME'
            }
        },
        {
            "brandName": "&OtherStories",
            "properties": {
                "srcImg": OtherStoriesImage,
                "brandLogo": OtherStoriesLogo,
                "brandRedirect": "https://www.stories.com/",
                "imgAlt": '&OtherStories',
                "anchorName": 'To stories.com'
            }
        },
        {
            "brandName": "Arket",
            "properties": {
                "srcImg": ArketImage,
                "brandLogo": ArketLogo,
                "brandRedirect": "https://www.arket.com/",
                "imgAlt": 'Arket',
                "anchorName": 'To arket.com'
            }
        }
    ]

export const brandsLogo = [
    {
        "brandId": 0,
        "brandName": "HM",
        "logo": HMlogo_black
    },
    {
        "brandId": 1,
        "brandName": "COS",
        "logo": Coslogo_black
    },
    {
        "brandId": 3,
        "brandName": "WEEKDAY",
        "logo": weekday_logo_black
    },
    {
        "brandId": 2,
        "brandName": "MONKI",
        "logo": monki_logo_black
    },
   
    {
        "brandId": 8,
        "brandName": "HMHome",
        "logo": hm_home_black_logo
    },
    {
        "brandId": 5,
        "brandName": "STORIES",
        "logo": and_other_stories_logo_black
    },
    {
        "brandId": 6,
        "brandName": "ARKET",
        "logo": arket_logo_black
    },
    {
        "brandId": 10,
        "brandName": "HMGroup",
        "logo": hm_group_logo
    },
    

]







