const SessionStorageService = {
    // Method to set an item in session storage
    setItem: (key, value) => {
      sessionStorage.setItem(key, JSON.stringify(value));
    },
  
    // Method to get an item from session storage
    getItem: (key) => {
      const value = sessionStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    },
  
    // Method to remove an item from session storage
    removeItem: (key) => {
      sessionStorage.removeItem(key);
    },
  
    // Method to clear all items from session storage
    clear: () => {
      sessionStorage.clear();
    },
  };
  
  export default SessionStorageService;
  