import React, { useContext, useState, useCallback, memo, useRef, useEffect } from "react";
import {
    PrimaryButton,
    SecondaryButton,
    HeadingLarge,
    BodyText,
    RadioButtonInput,
    Divider,
    TextArea,
    Spinner,
    ToastNotification
} from "@hm-group/fabric-components";
import "./APSurveyComponent.scss";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getTouchPoint, getkeyvalueText } from "../../util/helperfunctions";
import { submitAPFeedback } from "../../Services/DataService";
import { EVENTACTION, EVENTOUTCOME, commonConstants } from "../../util/constants";
import { UserContext } from "../../context/userContext";



/**
 * RadioFeedback component renders individual feedback questions with radio buttons and labels.
 *
 * @param {object} props - The properties object.
 * @param {object} props.value - The current question and its selected value.
 * @param {number} props.index - The index of the current question.
 * @param {number} props.length - The total number of questions.
 * @param {function} props.setValue - Callback to update the selected value for the current question.
 * @param {Array} props.rating - The current ratings array.
 * @param {boolean} props.showError - Flag to indicate if the error message should be displayed.
 * @param {boolean} props.isshowkey - Flag to indicate if keys should be shown.
 * @param {object} props.lslKeyValue - Object containing key-value data.
 *
 * @returns {JSX.Element} The rendered RadioFeedback component.
 */
const RadioFeedback = memo(({ value, index, length, setValue, rating, showError, isshowkey, lslKeyValue }) => {
    return (
        <div className="RadioFeedbackCard" role="group" aria-labelledby={`question-${index}`}>
            <BodyText Tag="p" id={`question-${index}`}>{`${index + 1} / ${length}`}</BodyText>
            <p>
            <BodyText Tag="p" className="labelHeading">{getkeyvalueText('A&PFeedback', `${value.qKey}`, isshowkey, lslKeyValue.lslKeyData)} </BodyText>
            <BodyText className="asterisk">*</BodyText>
            </p>
            <div className="APFeedbackRadioGroup">
                {[1, 2, 3, 4, 5].map((item) => (
                    <div key={item} className={item !== 5 ? "APFeedbackRadioEach" : ''}>
                        <RadioButtonInput
                            name={`question-${index}`}
                            onChange={() => setValue(index, item)}
                            labels={{ label: "" }}
                            value={item.toString()}
                            checked={rating[index] && rating[index].optionValue === item}
                            tabIndex={0}
                            aria-checked={rating[index].optionValue === item ? "true" : "false"}
                        />
                        <BodyText Tag="p" className="labelText">{`${item}`}</BodyText>
                    </div>
                ))}
            </div>
            <div className="agreeDisagreeBox" aria-hidden="true">
                <BodyText Tag="p" className="text">{getkeyvalueText('A&PFeedback', 'apstronglydisagree', isshowkey, lslKeyValue.lslKeyData)}</BodyText>
                <BodyText Tag="p" className="text agree">{getkeyvalueText('A&PFeedback', 'apstronglyagree', isshowkey, lslKeyValue.lslKeyData)}</BodyText>
            </div>
            {showError && rating[index].optionValue === null && (
                <BodyText Tag="p" className="errorText">{getkeyvalueText('A&PFeedback', 'apselectoption', isshowkey, lslKeyValue.lslKeyData)}</BodyText>
            )}
        </div>
    );
});

/**
 * APSurveyComponent renders a feedback survey form with radio buttons and a text area.
 *
 * @param {object} props - The properties object.
 * @param {function} props.onClose - Callback to handle closing the survey component.
 * @param {object} props.payloadData - Additional data to be sent with the feedback.
 *
 * @returns {JSX.Element} The rendered APSurveyComponent.
 */
const APSurveyComponent = ({ onClose, payloadData, sendAPSurveyAnalyticsData }) => {
    const location = useLocation();
    const isshowkey = location.state && location.state.isshowkey;
    const {lslKeyValue} = useContext(UserContext);
    const textAreaRef = useRef(null);
    const apFeedbackRef = useRef(null);


    const [feedbackState, setFeedbackState] = useState({
        feedbackRating: [
            { qKey: "apeasycopydata", optionValue: null },
            { qKey: "apeaseaccessdata", optionValue: null },
            { qKey: "apeasyunderstanddata", optionValue: null },
        ],
        comment: "",
        showError: false,
        showApiError: false,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(true);

    const questionRefs = useRef([]);
    const isAddExtract = location.state && location.state.aprequesttype == 'ADDITIONAL'

    /**
    * Handles setting the value for a specific feedback question.
    *
    * @param {number} index - The index of the question to update.
    * @param {number} value - The selected value for the question.
    */
    const handleSetValue = useCallback((index, value) => {
        setFeedbackState(prevState => {
            const newFeedbackRating = [...prevState.feedbackRating];
            newFeedbackRating[index].optionValue = value;
            return {
                ...prevState,
                feedbackRating: newFeedbackRating
            };
        });
    }, []);

    const handleToastClose = () => {
        setIsVisible(false)
      }
    /**
     * Handles the continue button click event to submit feedback.
     */
    const handleContinue = async () => {
        const firstUnansweredIndex = feedbackState.feedbackRating.findIndex(item => item.optionValue === null);
        if (firstUnansweredIndex !== -1) {
            setFeedbackState(prevState => ({ ...prevState, showError: true }));
            questionRefs.current[firstUnansweredIndex].scrollIntoView({ behavior: 'smooth', block: 'start' });
            return;
        }
        setIsLoading(true);

        const payload = {
            options: feedbackState.feedbackRating,
            additionalFeedback: feedbackState.comment,
            surveyType: isAddExtract ? 'ADDITIONAL_AP' : 'AP',
            ...payloadData,
            surveyId: "apleavefeedback",
        };

        try {
            const response = await submitAPFeedback(payload);
            if (response.status === 200) {
                onClose(true);
                sendAPSurveyAnalyticsData(EVENTACTION.apSurveySubmit,EVENTOUTCOME.success);
            } else {
                setFeedbackState(prevState => ({ ...prevState, showApiError: true }));
                sendAPSurveyAnalyticsData(EVENTACTION.apSurveySubmit,EVENTOUTCOME.failure);
            }
        } catch (err) {
            setFeedbackState(prevState => ({ ...prevState, showApiError: true }));
            sendAPSurveyAnalyticsData(EVENTACTION.apSurveySubmit,EVENTOUTCOME.failure);
        } finally {
            setIsLoading(false);
        }
    };

    /**
    * Handles changes in the comment text area.
    *
    * @param {object} e - The event object.
    */
    const handleCommentChange = useCallback((e) => {
        setFeedbackState(prevState => ({
            ...prevState,
            comment: e.target.value
        }));
    }, []);



    /**
     * Scrolls the TextArea into view when it gains focus.
     */
    useEffect(() => {
        if (textAreaRef.current && apFeedbackRef.current && [
        commonConstants.OS_ANDROID,
        ].includes(getTouchPoint())) {

            const handleFocus = () => {

                const defaultMargin = '33vh'; // 30% of viewport height
                // Scroll the text area into view on Android devices

                apFeedbackRef.current.style.marginBottom = defaultMargin;
                textAreaRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

            };
           
            let blurTimeout;
            const handleBlur = () => {
                if (apFeedbackRef.current) {
                    // Delay resetting the margin added to prioritize the click event on submit button
                    blurTimeout = setTimeout(() => {
                        apFeedbackRef.current.style.marginBottom = '0px';
                    },0);
                }
            };

            const textAreaElement = textAreaRef.current;

            if (textAreaElement) {
                textAreaElement.addEventListener('focus', handleFocus);
                textAreaElement.addEventListener('blur', handleBlur);
            }

            return () => {
                if (textAreaElement) {
                    textAreaElement.removeEventListener('focus', handleFocus);
                    textAreaElement.removeEventListener('blur', handleBlur);
                    clearTimeout(blurTimeout);
                }
            };
        }
    }, [textAreaRef, apFeedbackRef]);



    return (
        <div ref={apFeedbackRef}>
            {isLoading ? (
                <div className="loader_section">
                    <Spinner color="black" isRendered={isLoading} />
                </div>
            ) : ''}
            <div className={isLoading ? 'disabled' : ''}>
                {feedbackState.showApiError && (
                    <div className="apiErrorBanner">
                    <ToastNotification
                            autoDelete={false}
                            labels={{
                                close: 'Close'
                            }}
                            onClose={handleToastClose}
                            isVisible={isVisible}
                            message={getkeyvalueText(
                                'accessPersonalData',
                                'accessdataerrormsgLabel',
                                isshowkey,
                                lslKeyValue.lslKeyData,
                            )}
                            severity="error"
                        />
                    </div>
                )}
                <div>
                    <HeadingLarge Tag="h1" className="apFeedbackHeading" tabIndex={0}>
                        {getkeyvalueText('A&PFeedback', 'apleavefeedback', isshowkey, lslKeyValue.lslKeyData)}
                    </HeadingLarge>
                    <BodyText Tag="p" className="thanksText" alignment="center" tabIndex={0}>
                        {getkeyvalueText('A&PFeedback', 'apthankyoufeedback', isshowkey, lslKeyValue.lslKeyData)}
                    </BodyText>
                    <BodyText Tag="p" alignment="center" tabIndex={0}>
                        {getkeyvalueText('A&PFeedback', 'apratestatements', isshowkey, lslKeyValue.lslKeyData)}
                    </BodyText>
                    {feedbackState.feedbackRating.map((item, index) => (
                        <div key={index} ref={el => questionRefs.current[index] = el}>
                            <RadioFeedback
                                value={item}
                                index={index}
                                length={feedbackState.feedbackRating.length}
                                setValue={handleSetValue}
                                rating={feedbackState.feedbackRating}
                                showError={feedbackState.showError}
                                isshowkey={isshowkey}
                                lslKeyValue={lslKeyValue}
                            />
                            <Divider className="divider" />
                        </div>
                    ))}

                    <TextArea
                        className="textArea"
                        inputMode="text"
                        label={getkeyvalueText('commonKey', 'anythingelselabel', isshowkey, lslKeyValue.lslKeyData)}
                        maxLength={255}
                        name="text-area"
                        value={feedbackState.comment}
                        onChange={handleCommentChange}
                        placeholder={getkeyvalueText('FeedBack', 'donotenternumbersemailLabel', isshowkey, lslKeyValue.lslKeyData)}
                        tabIndex={0}
                        aria-label="Additional comments"
                        ref={textAreaRef}
                    />
                    <div className="actionButton">
                        <PrimaryButton onClick={handleContinue} tabIndex={0}>
                            {getkeyvalueText('commonKey', 'submitbtn', isshowkey, lslKeyValue.lslKeyData)}
                        </PrimaryButton>
                        <SecondaryButton onClick={() => onClose(false)} tabIndex={0}>
                            {getkeyvalueText('commonKey', 'nolongerparticipate', isshowkey, lslKeyValue.lslKeyData)}
                        </SecondaryButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default APSurveyComponent;
