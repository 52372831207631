import React,{useContext} from 'react';
import { getkeyvalueText } from '../../../util/helperfunctions';
import { BodyText } from '@hm-group/fabric-components'
import { UserContext } from '../../../context/userContext';


const LogOff = (isshowkey) => {

    const {lslKeyValue} = useContext(UserContext);

    return (
        <div>
            <BodyText Tag="p">{getkeyvalueText('Lightboxes', 'abt2logofffrmmpdLabel', isshowkey, lslKeyValue.lslKeyData)}</BodyText>
        </div>
    )

}

export default LogOff;