import React, { memo } from 'react'
import { CheckboxInput, BodyText } from '@hm-group/fabric-components'
import { getkeyvalueText } from '../../../util/helperfunctions'
import { nameConstant } from '../subscriptionConfiguraton';

const CheckBoxChannel = memo(({ handleSelect, isChecked, label,lslKeyValue,isEmailSelected }) => {
  const isshowkey = false;
  return (
  <CheckboxInput
    className="spacing-s"
    labels={{
      errorLabel: 'Error message',
      label: getkeyvalueText('editSubscriptions', label === nameConstant.PHONE ? 'smschannelLabel' : 'emailchannelLabel', isshowkey, lslKeyValue.lslKeyData),
    }}
    isChecked={isChecked || isEmailSelected}
    isDisabled={isEmailSelected}
    name={`Checkbox-${label}`}
    onChange={(e) => handleSelect(label, e.target.checked)}
  />
  )
})

export const Channel = memo(
  ({ channels, value, handleSelect, isChannelError, lslKeyValue, isDOI, isNewSubscription }) => {
    const isshowkey = false
    const isEmailSelected = isNewSubscription && isDOI
    return (
      <div className="spacing-m">
        <div className="spacing-m">
          <BodyText>
            {getkeyvalueText('editSubscriptions', 'commchannelLabel', isshowkey, lslKeyValue.lslKeyData)}
          </BodyText>
        </div>

        <div className="radio-group">
          {channels.map((each) => (
            <CheckBoxChannel
              label={each}
              handleSelect={handleSelect}
              isChecked={value.includes(each)}
              lslKeyValue={lslKeyValue}
              isEmailSelected={each === nameConstant.EMAIL ? isEmailSelected : false}
            />
          ))}

          {isChannelError && (
            <BodyText Tag="p" className="errorText">
              {getkeyvalueText('A&PFeedback', 'apselectoption', isshowkey, lslKeyValue.lslKeyData)}
            </BodyText>
          )}
        </div>
      </div>
    )
  },
)
