import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import propTypes from 'prop-types';
import { Component } from 'react';


const ProtectedRoute = ({ component: Component, user,userName, ...rest }) => {

  let isUser = user;
  let isuserName = userName;

  //If Already Logged In, Capture Login Identifier from Session Storage
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  if (isLoggedIn) {
      isUser = true;
  }

  const isvtagPresent = localStorage.getItem("vtag");

  const useridentifier = localStorage.getItem("userName");
  if (useridentifier != null || useridentifier != "") {
      isuserName = useridentifier;
  }

  return (
    <Route {...rest} render={
      props => {
        if (isUser && (isuserName != "" || isuserName != null)) {
          return <Component {...rest} {...props} />
        } else {
          return <Redirect to={
            {
              pathname: '/Logoff',
            }
          } />
        }
      }
    } />
  )
}

ProtectedRoute.propTypes = {
  component: propTypes.func,
  user: propTypes.bool,
  userName:propTypes.string
}

export default ProtectedRoute;