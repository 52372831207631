import React, { createContext, useState, useEffect } from 'react';
import SessionStorageService from '../Services/SessionStorageService';

// Create Context
const CustomerContext = createContext();

// Initial customer data object with empty strings
const initialCustomerData = {
    "preferredLanguage": "",
    "caseIdentifier": "",
    "apDownloadDataExpiresOn": "",
    "businessPartnerId": "",
    "countryCode": "",
    "corporateBrandId": "",
    "emailAddress": "",
    "clubRole": "",
    "onlineOMSCustomerIdentifier": "",
    "frequencyTextFashionNews": "",
    "clubMemberStatus": "",
    "firstName": "",
    "frequencyFashionNews": "",
    "hmCatalogueSubscription": "",
    "revokeConsent": "",
    "APRequestDate": "",
    "APRequestStatus": "",
    "ccpaRequestDate": ""
};

// Create Provider component
const CustomerProvider = ({ children }) => {
    const [customer, setCustomer] = useState(() => {
        // Retrieve the data from sessionStorage on initial render
        const savedCustomer = SessionStorageService.getItem('customer');
        return savedCustomer ? JSON.parse(savedCustomer) : {initialCustomerData}; 
    });

    useEffect(() => {
        // Save the data to sessionStorage whenever it changes
        SessionStorageService.setItem('customer', JSON.stringify(customer));
    }, [customer]);

    const updateCustomer = (newDetails) => {
        setCustomer((prevCustomer) => ({
            ...prevCustomer,
            ...newDetails,
        }));
    };

    return (
        <CustomerContext.Provider value={{ customer, updateCustomer }}>
            {children}
        </CustomerContext.Provider>
    );
};

export { CustomerContext, CustomerProvider };
