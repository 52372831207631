import React, { useContext, useState, useMemo, useCallback, useEffect } from 'react'
import {
  TextInput,
  BodyText,
  HeadingLarge,
  SecondaryButton,
  PrimaryButton,
  ToastNotification,
} from '@hm-group/fabric-components'
import { getBrandIdbyName, getBrandImagebyID, getCountryNamebyCode, getkeyvalueText } from '../../util/helperfunctions'
import { deletePersonalData, sendcicotpemailrequest, verifycicotpemailrequest } from '../../Services/DataService'
import { AnalyticsContext } from '../../context/analyticsContext'
import {
  EVENTACTION,
  EVENTOUTCOME,
  EVENTTYPE,
  PAGE,
  cicConstants,
  labelMappings,
  otpStatus,
} from '../../util/constants'
import './CICOtpVerification.scss'
import { UserContext } from '../../context/userContext'
import useTimer from "../../components/TimerHook/TimerHook";
import { CustomerContext } from "../../context/customerDetailsContext";


/**
 * Component for handling Customer Insights Center (CIC) OTP verification.
 * @component
 * @param {Object} props - Component props.
 * @param {Function} props.handleCicContinue - Function to handle continue action after OTP verification.
 * @returns {JSX.Element}
 */
export const CICOtpVerification = ({ handleCicContinue, payloadData }) => {
  // Context hooks
  const {lslKeyValue} = useContext(UserContext)
  const { preferredLanguage, lslKeyData } = lslKeyValue
  const { analyticsData, setAnalyticsData, updateAnalyticsData } = useContext(AnalyticsContext)
  const islslKey = false
  const isshowkey = false // temporary
  const [disableSendTimer, setDisableSendTimer] = useState(false)
  const { customer } = useContext(CustomerContext);
  const { corporateBrandId } = customer;
  const [codeSent, setCodeSent] = useState(false)

  const [isVisible, setIsVisible] = useState(true);


  // State variables
  const [formData, setFormData] = useState({
    otp: '',
    otpVerificationStatus: null,
    isApiFailed: false,
    showBanner: true,
    showError: false,
  })

  // Destructure formData for easier access
  const { otp, otpVerificationStatus, showError, isApiFailed, showBanner } = formData
  const { timeLeft, startTimer, stopTimer } = useTimer(() => setDisableSendTimer(false))
  const { userName, country, brand, clubRole, businessPartnerId, onlineOMSCustomerIdentifier } = payloadData


  useEffect(() => {

    handleStart()
    handleCodeSent()

  },[])

  const handleToastClose = () => {
    setIsVisible(false)
  }


  // Analytics update function
  const updateAnalytics = (outcome, action) => {
    updateAnalyticsData({
      ...analyticsData,
      managedCountry: country,
      managedBrand: getBrandIdbyName(brand),
      eventType: EVENTTYPE.cicVerification,
      page: PAGE.cicAreYouSurePage,
      eventAction: action,
      eventOutcome: outcome,
      managedBPID: businessPartnerId,
      managedCustomerType: clubRole,
    })
  }

  // Memoized CIC OTP request parameters
  const postcicotpParams = useMemo(() => {
    const params = {
      emailid: userName,
      language: preferredLanguage,
      countryCode: country,
      brandId: getBrandIdbyName(brand),
      data: 'temp',
      cicCountryName: getCountryNamebyCode(country),
      customerType: clubRole,
    }

    // Populate additional params from labelMappings
    Object.entries(labelMappings).forEach(([key, value]) => {
      params[key] = getkeyvalueText(value, key, false, lslKeyData)
    })

    return params
  }, [userName, country, clubRole, lslKeyData, brand, preferredLanguage])

  // Memoized payload for OTP verification request
  const payload = useMemo(
    () => ({
      brandid: getBrandIdbyName(brand),
      countrycode: country,
      emailid: userName,
      otp,
    }),
    [brand, country, userName, otp],
  )

  // Handler for OTP input change
  const handleOtpChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      otp: e.target.value,
      showError: false, // Clear error message on input change
    }))
  }

  const handleCodeSent = () => {
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false)
    }, 5000);
  }
  

  const handleStart = () => {
    startTimer(60)
    setDisableSendTimer(true)
  }

  const handleStop = () => {
    stopTimer()
    setDisableSendTimer(false)
  }

  // Handles successful OTP verification
  const handleOtpSuccess = async () => {
    setFormData((prevState) => ({
      ...prevState,
      otpVerificationStatus: EVENTOUTCOME.success,
      showError: false,
      showBanner: false,
    }))
  }

  // Handles failed OTP verification
  const handleOtpFailure = () => {
    setFormData((prevState) => ({
      ...prevState,
      otpVerificationStatus: EVENTOUTCOME.failure,
      showError: true,
      showBanner: true,
      isApiFailed: true,
    }))
    // Update analytics on OTP verification failure
    updateAnalytics(EVENTOUTCOME.success, EVENTACTION.cicOtpVerifiedError)
  }

  // Sends a new OTP request
  const resendOtp = async () => {
    setFormData((prevState) => ({
      ...prevState,
      otp: '', // Clear OTP input
      showError: false,
      showBanner: false,
      otpVerificationStatus: null,
    }))
    handleStop()
    try {
      // Attempt to resend OTP via API call
      const resendOtpStatus = await sendcicotpemailrequest(postcicotpParams)
      // Determine event outcome based on API response
      const eventOutcome = resendOtpStatus.status === 200 ? EVENTOUTCOME.success : EVENTOUTCOME.failure

      if (resendOtpStatus.status === 200) {
        setCodeSent(true)
        handleStart()
        setIsVisible(true);
        setTimeout(() => {
          setIsVisible(false)
        }, 5000)
      }
      
      // Update analytics based on OTP resend status
      updateAnalytics(eventOutcome, EVENTACTION.cicOtpSent)
      
      // Update component state based on resend outcome
      setFormData((prevState) => ({
        ...prevState,
        isApiFailed: eventOutcome === EVENTOUTCOME.failure,
        showBanner: true,
      }))
      setIsVisible(true);
    } catch (e) {
      // Handle API error during OTP resend
      updateAnalytics(EVENTOUTCOME.failure, EVENTACTION.cicOtpSent)
      setFormData((prevState) => ({
        ...prevState,
        isApiFailed: true,
        showBanner: true,
      }))
      setIsVisible(true);
    }
  }

  // Verifies the entered OTP
  const verifyOtp = useCallback(async () => {
    if (!otp) {
      // Show error if OTP field is empty
      setFormData((prevState) => ({
        ...prevState,
        showError: true,
        showBanner: false,
      }))
      return
    }

    try {
      // Attempt to verify OTP via API call
      const otpResponse = await verifycicotpemailrequest(payload)
      const isOtpCorrect =
        otpResponse.data.otpStatus === otpStatus.correctOtp || otpResponse.data.otpStatus === otpStatus.alreadyVerified
      // Determine event outcome based on OTP verification result
      const eventOutcome = isOtpCorrect ? EVENTOUTCOME.success : EVENTOUTCOME.failure

      // Update component state based on OTP verification result
      setFormData((prevState) => ({
        ...prevState,
        otpVerificationStatus: eventOutcome,
        showError: !isOtpCorrect,
        showBanner: false,
      }))
      setIsVisible(true);    
      // Handle further actions based on OTP verification result
      if (isOtpCorrect) {
        updateAnalytics(EVENTOUTCOME.success, EVENTACTION.cicOtpVerified)
        initiateCleanising()
      } else if (!isOtpCorrect) {
        updateAnalytics(EVENTOUTCOME.success, EVENTACTION.cicOtpVerifiedError)
      } else {
        handleOtpFailure()
      }
    } catch (error) {
      // Handle error during OTP verification
      handleOtpFailure()
      updateAnalytics(EVENTOUTCOME.failure, EVENTACTION.cicOtpVerifiedError)
    }
  }, [otp, payload, updateAnalytics])

  // initiate cleanising
  const initiateCleanising = async () => {
    const deleteRequestParam = {
      brandId: getBrandIdbyName(brand),
      countryCode: country,
      businessPartnerId: businessPartnerId,
      customerId: onlineOMSCustomerIdentifier,
      customerType: clubRole && clubRole.toUpperCase(),
      password: 'xx', // TODO : speak with backend team : temoporory
    }
    try {
      // Attempt to delete personal data via API call
      const delteResponse = await deletePersonalData(deleteRequestParam)
      if (delteResponse.status === 200) {
        // Proceed with the next step after OTP verification
        await handleOtpSuccess()
        updateAnalytics(EVENTOUTCOME.success, EVENTACTION.deletePersonalDataInitiated)
        handleCicContinue(cicConstants.cicSurveyFlow)
      } else {
        setFormData((prevState) => ({
          ...prevState,
          isApiFailed: true,
          showBanner: true,
        }))
        updateAnalytics(EVENTOUTCOME.failure, EVENTACTION.deletePersonalDataInitiated)
      }
    } catch (error) {
      setFormData((prevState) => ({
        ...prevState,
        isApiFailed: true,
        showBanner: true,
      }))
      updateAnalytics(EVENTOUTCOME.success, EVENTACTION.deletePersonalDataInitiated)
    }
  }

  // Memoized brand image source (replace with actual function)
  const brandImageSrc = useMemo(() => getBrandImagebyID(corporateBrandId), [])

  // JSX rendering
  return (
    <div className="pop_up_width">
      <div className="brand__img">
        <img className="logoImage" src={brandImageSrc} alt="Brand Logo" />
      </div>

      <HeadingLarge Tag="h1" className="cicFeedbackHeading" tabIndex={0}>
        {getkeyvalueText('Lightboxes', 'custconfirmLabel', islslKey, lslKeyData)}
      </HeadingLarge>

      <BodyText Tag="p" className="spacing">
        {getkeyvalueText('Lightboxes', 'custvercodesentLabel', islslKey, lslKeyData)}
      </BodyText>
      <BodyText Tag="p" className="spacing">
        {getkeyvalueText('Lightboxes', 'custtoentercodeLabel', islslKey, lslKeyData)}
      </BodyText>
      <BodyText Tag="p" className="spacing">
        {getkeyvalueText('Lightboxes', 'custtoinitiatecicLabel', islslKey, lslKeyData)}
      </BodyText>

      <TextInput
        className="spacing"
        isDisabled
        id="inputEmail"
        data-testid="inputEmail"
        value={userName}
        label={getkeyvalueText('login', 'userLabel', islslKey, lslKeyData)}
      />

      <TextInput
        className="spacing"
        id="inputOtp"
        data-testid="inputOtp"
        onChange={handleOtpChange}
        value={otp}
        isValid={otpVerificationStatus === EVENTOUTCOME.success}
        label={`${getkeyvalueText('Lightboxes', 'disverificationcodeLabel', islslKey, lslKeyData)}*`}
        isInvalid={otpVerificationStatus === EVENTOUTCOME.failure || (!otpVerificationStatus && showError)}
        helperText={
          showError &&
          (otpVerificationStatus === EVENTOUTCOME.failure
            ? getkeyvalueText('Lightboxes', 'discentervalidLabel', islslKey, lslKeyData)
            : !otp
            ? getkeyvalueText('Lightboxes', 'discentercodeLabel', islslKey, lslKeyData)
            : '')
        }
      />

      {disableSendTimer ? (
            <BodyText alignment="center" Tag="p" lineClamp="1" isPreFormatted>
              {getkeyvalueText('login', 'resendemailLabel', islslKey, lslKeyData).split('{XX}')[0]}
              {timeLeft}
              {getkeyvalueText('login', 'resendemailLabel', islslKey, lslKeyData).split('{XX}')[1]}
            </BodyText>
          ) : null}
      
      {/* {codeSent ?  */}
      <div className="actionButton">
        <PrimaryButton data-testid="verify_code_btn" id="verify_btn" onClick={verifyOtp}>
          {getkeyvalueText('Lightboxes', 'disverifycodeLabel', islslKey, lslKeyData)}
        </PrimaryButton>
        <SecondaryButton className="spacing" data-testid="send_new_code_btn" id="send_newcode_btn" isDisabled={disableSendTimer} onClick={resendOtp}>
          {getkeyvalueText('Lightboxes', 'discsendcodeLabel', islslKey, lslKeyData)}
        </SecondaryButton>
      </div>
      {/* : ''} */}

      {showBanner && (
        <div className={`apiSucessErrorToast ${isApiFailed ? 'toast-error' : 'toast-success'}`}>
        <ToastNotification
          className="spacing"
          autoDelete={isApiFailed ? false : true}
          autoDeleteTime={5000}
            labels={{
              close: 'Close'
            }}
          onClose={handleToastClose}
          isVisible={isVisible}
          message={getkeyvalueText(
            isApiFailed ? 'accessPersonalData' : 'login',
            isApiFailed ? 'accessdataerrormsgLabel' : 'custemailsuccessLabel',
            true,
            lslKeyData,
          )}
          severity={isApiFailed ? 'error' : 'success'}
        />
        </div>
      )}
    </div>
  )
}
