import { useState, useEffect, useRef } from 'react';

// Function to format time as MM:SS
const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
};

// Custom hook for timer
const useTimer = (onTimerEnd) => {
  const [timeLeft, setTimeLeft] = useState('00:00');
  const [timeInSeconds, setTimeInSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const countdownTimer = useRef(null);

  useEffect(() => {
    if (isRunning && timeInSeconds > 0) {
      countdownTimer.current = setInterval(() => {
        setTimeInSeconds(prevTime => {
          const newTime = prevTime - 1;
          setTimeLeft(formatTime(newTime));
          if (newTime <= 0) {
            clearInterval(countdownTimer.current);
            onTimerEnd();
            setIsRunning(false);
          }
          return newTime;
        });
      }, 1000);
    }

    return () => clearInterval(countdownTimer.current);
  }, [isRunning, timeInSeconds, onTimerEnd]);

  const startTimer = (newTime) => {
    setIsRunning(true);
    setTimeInSeconds(newTime);
    setTimeLeft(formatTime(newTime));
  };

  const stopTimer = () => {
    clearInterval(countdownTimer.current);
    setTimeInSeconds(0);
    setTimeLeft(formatTime(0));
    setIsRunning(false);
  };

  return {
    timeLeft,
    startTimer,
    stopTimer,
  };
};

export default useTimer;
