import React, { useContext } from 'react';
import { getkeyvalueText } from '../../../util/helperfunctions';
import { BodyText } from '@hm-group/fabric-components'
import { UserContext } from '../../../context/userContext';

const NotifySessionTimeout = ({isshowkey}) => {

    const {lslKeyValue} = useContext(UserContext);

    return (
        <div>
            <BodyText alignment="center" Tag="p">{getkeyvalueText('Lightboxes', 'inactivitytimeoutLabel', isshowkey, lslKeyValue.lslKeyData)}</BodyText>
            <BodyText alignment="center" Tag="p">{getkeyvalueText('Lightboxes', 'clckanywhere2extendsessionLabel', isshowkey, lslKeyValue.lslKeyData)}</BodyText>
        </div>
    )

}

export default NotifySessionTimeout;