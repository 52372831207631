import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { getkeyvalueText, identifyEvent } from '../../../util/helperfunctions'
import { Accordion, BodyText, HeadingSmall, MetaText, GhostLink } from '@hm-group/fabric-components'
import { InfoIcon } from '@hm-group/fabric-icons'
import {
  APRequestStatusValue,
  CUSTOMERTYPE,
  EVENTACTION,
  EVENTOUTCOME,
  EVENTTYPE,
  PAGE,
  brandNameFromID,
} from '../../../util/constants'
import { AnalyticsContext } from '../../../context/analyticsContext'
import { CustomerContext } from '../../../context/customerDetailsContext'
import { CustomerDataContext } from '../../../context/customerDataContext'
import { UserContext } from '../../../context/userContext'

const AccessDataSection = ({ updateExpanded, accordianExpanded, handleClose }) => {
  const history = useHistory()

  const { lslKeyValue } = useContext(UserContext)
  const { customer } = useContext(CustomerContext)
  const { customerData } = useContext(CustomerDataContext)
  const { analyticsData, updateAnalyticsData } = useContext(AnalyticsContext)
  const isshowkey = false // temporary

  const {
    businessPartnerId,
    corporateBrandId,
    countryCode,
    onlineOMSCustomerIdentifier,
    clubRole,
    APRequestStatus,
    APRequestDate,
    apDownloadDataExpiresOn,
  } = customer
  const { guestBusinessPartnerId, guestAPRequestStatus, guestAPDownloadDataExpiresOn, guestAPRequestDate } =
    customerData

  const APRequestMomentDate = APRequestDate ? moment(new Date(APRequestDate)) : null
  const APRequestGuestMomentDate = guestAPRequestDate ? moment(new Date(guestAPRequestDate)) : null
  const guestMomentExpiryDate = guestAPDownloadDataExpiresOn ? moment.unix(guestAPDownloadDataExpiresOn) : null
  const APMomentExpiryDate = apDownloadDataExpiresOn ? moment.unix(apDownloadDataExpiresOn) : null

  const currentDate = moment(Date.now())

  const updateAndRedirect = (path, isGuest = false) => {
    updateAnalyticsData({
      ...analyticsData,
      managedCountry: countryCode,
      managedBrand: corporateBrandId,
      eventType: EVENTTYPE.ap,
      page: PAGE.apDownloadManagePage,
      eventAction: EVENTACTION.apDownloadReadMore,
      eventOutcome: EVENTOUTCOME.success,
      managedBPID: businessPartnerId,
      managedCustomerType: isGuest ? CUSTOMERTYPE.guest : clubRole,
      managedBPID: isGuest ? guestBusinessPartnerId : businessPartnerId,
    })
    history.push({ pathname: path, state: { isGuest } })
  }

  const handleAnchorClick = (customerType) => {
    if (customerType !== 'guest' && APRequestStatus) {
      if ([APRequestStatusValue.SUCCESS, APRequestStatusValue.CLOSED].includes(APRequestStatus.toLowerCase())) {
        if (APMomentExpiryDate && currentDate.isBefore(APMomentExpiryDate)) {
          updateAndRedirect('/downloadPersonalData')
        } else {
          updateAndRedirect('/accessPersonalData')
        }
      }
    } else if (customerType === 'guest' && guestAPRequestStatus) {
      if ([APRequestStatusValue.SUCCESS, APRequestStatusValue.CLOSED].includes(guestAPRequestStatus.toLowerCase())) {
        if (guestMomentExpiryDate && currentDate.isBefore(guestMomentExpiryDate)) {
          updateAndRedirect('/downloadPersonalData', true)
        } else {
          updateAndRedirect('/accessPersonalData', true)
        }
      }
    } else {
      updateAndRedirect('/accessPersonalData', customerType === 'guest' ? true : false)
    }
  }
  const renderDataRequestedText = (status, date,isGuest = false) => {
    if (!status || !date) return ''
    const formattedStatus = status.toLowerCase()
    const isPendingOrInProgress = ['pending', 'inprogress'].includes(formattedStatus)

    if (!isPendingOrInProgress) return ''

    return (
      <div className="services_request" onClick={(e) => handleClose(isGuest)}>
        <InfoIcon size="small"  className='infoIconMargin'/>
        <MetaText Tag="p" className="services_request_text">
          {formatTextWithDate('ManageServices', 'copydatareqeustedLabel', date, isshowkey, lslKeyValue.lslKeyData)}
        </MetaText>
      </div>
    )
  }

  const renderContinueButton = (customerType) => (
    <GhostLink
      onClick={() => handleAnchorClick(customerType)}
      onKeyDown={(event) => {
        identifyEvent(event) && handleAnchorClick(customerType)
      }}
      tabIndex={0}
    >
      {getkeyvalueText('commonKey', 'continuebtn', isshowkey, lslKeyValue.lslKeyData)}
    </GhostLink>
  )

  const formatTextWithDate = (textKey, label, date, isShowKey, keyValueData) => {
    if (!date) return ''
    const text = getkeyvalueText(textKey, label, isShowKey, keyValueData)
    const formattedDate = String(date.format('DD MMMM YYYY'))
      .replace(date.format('MMMM'), getkeyvalueText('Month', date.format('MMMM'), isShowKey, keyValueData))
      .trim()

    const [prefix, suffix] = text.split('{dd mmm yyyy}')
    const punctuation = text.endsWith('.') ? '' : '.'

    return `${prefix}${formattedDate}${suffix || ''}${punctuation}`
  }

  const renderDataAvailableText = (status, expiryDate) => {
    if (!expiryDate || !status) return ''

    const formattedStatus = status.toLowerCase()
    const isAvailable = formattedStatus === APRequestStatusValue.SUCCESS

    if (!isAvailable && currentDate.isAfter(expiryDate)) return ''

    return (
      <div className="services_request_closed">
        <MetaText Tag="p" className="services_request_text">
          {formatTextWithDate('A&PDownloadPage', 'a&pdatareadyLabel', expiryDate, isshowkey, lslKeyValue.lslKeyData)}
        </MetaText>
      </div>
    )
  }

  const renderGuestContinueButton = () => {
    if (!guestAPRequestStatus) return renderContinueButton('guest')
    const lowerStatus = guestAPRequestStatus.toLowerCase()
    if (lowerStatus === APRequestStatusValue.PENDING || lowerStatus === APRequestStatusValue.INPROGRESS) return ''
    if ([APRequestStatusValue.SUCCESS, APRequestStatusValue.CLOSED].includes(lowerStatus)) {
      if (guestMomentExpiryDate && currentDate.isBefore(guestMomentExpiryDate)) {
        return renderContinueButton('guest')
      }
    }
    if (lowerStatus === APRequestStatusValue.CLOSED) return renderContinueButton('guest')
  }

  const renderAPContinueButton = () => {
    if (!APRequestStatus) return renderContinueButton()
    const lowerStatus = APRequestStatus.toLowerCase()
    if (lowerStatus === APRequestStatusValue.PENDING || lowerStatus === APRequestStatusValue.INPROGRESS) return ''
    if ([APRequestStatusValue.SUCCESS, APRequestStatusValue.CLOSED].includes(lowerStatus)) {
      if (APMomentExpiryDate && currentDate.isBefore(APMomentExpiryDate)) {
        return renderContinueButton()
      }
    }
    if (lowerStatus === APRequestStatusValue.CLOSED) return renderContinueButton()
  }

  return (
    <div className="access_section">
      <Accordion
        isExpanded={accordianExpanded['accessDataAccord']}
        onClick={() => updateExpanded('accessDataAccord')}
        accordionItems={[
          {
            content: (
              <div>
                {/* account render */}
                <div className='spacing-section'>
                  <div className="access__services_text services_msg">
                    <BodyText Tag="p" className="managed_service_text">
                      {getkeyvalueText('ManageServices', 'exportcopyLabel', isshowkey, lslKeyValue.lslKeyData)}
                    </BodyText>
                  </div>
                  <div className="align-manage-items">
                    {/* membership or account render */}
                    <HeadingSmall Tag="h1" className="align-with-link">
                      {clubRole == 'z002'
                        ? getkeyvalueText('LandingPage', 'holdmembershipLabel', isshowkey, lslKeyValue.lslKeyData)
                        : clubRole == 'z004' || (clubRole == 'z003' && onlineOMSCustomerIdentifier)
                        ? getkeyvalueText('LandingPage', 'holdaccntLabel', isshowkey, lslKeyValue.lslKeyData)
                        : ''}
                    </HeadingSmall>

                    {/* continue button render */}
                    {renderAPContinueButton()}
                  </div>

                  {/* request status render for customer */}
                  <div>
                    {renderDataRequestedText(APRequestStatus, APRequestMomentDate,false)}
                    {renderDataAvailableText(APRequestStatus, APMomentExpiryDate)}
                  </div>
                </div>

                {/* guest account render */}
                {guestBusinessPartnerId != null ? (
                  <div>
                    <div className="align-manage-items">
                      <HeadingSmall Tag="h1" className="line-height align-with-link">
                        {getkeyvalueText('LandingPage', 'holdguestaccntLabel', isshowkey, lslKeyValue.lslKeyData)}
                      </HeadingSmall>

                      {renderGuestContinueButton()}
                    </div>
                    {/* request status render for guest */}
                    <div>
                      {renderDataRequestedText(guestAPRequestStatus, APRequestGuestMomentDate,true)}
                      {renderDataAvailableText(guestAPRequestStatus, guestMomentExpiryDate)}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ),
            heading: (
              <BodyText Tag="p">
                {getkeyvalueText('ManageServices', 'accessdataLabel', isshowkey, lslKeyValue.lslKeyData)}
              </BodyText>
            ),
            id: '1',
          },
        ]}
        defaultId="0"
        variant="fill"
      />
    </div>
  )
}

export default AccessDataSection
