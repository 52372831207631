import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React, { createContext } from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import './assets/fonts_en.css'
import reportWebVitals from './reportWebVitals'
// import { Provider } from 'react-redux';
import Routes from './routes/Routes'
import { BrowserRouter as Router, Route, NavLink, Redirect, Switch } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import ReactGA from 'react-ga'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './util/msal/authConfig'
import './Services/axiosInterceptor'
// import './util/setupProxy'

import theme from './theme'

// Create link tags to access SCSS variables
const tokensPackageJson = require('@hm-group/fabric-tokens/package.json')

const createLinkTags = () => {
  let linkElemFonts = document.createElement('link')
  let linkElemProps = document.createElement('link')
  linkElemFonts.setAttribute('href',`${tokensPackageJson.devBrandAssets.fonts['hmGroup']}`);
  linkElemFonts.setAttribute('rel', 'stylesheet')

  linkElemProps.setAttribute('href',`${tokensPackageJson.devBrandAssets.cssCustomProperties['hmGroup']}`);
  linkElemProps.setAttribute('rel', 'stylesheet')

  document.head.appendChild(linkElemFonts)
  document.head.appendChild(linkElemProps)
}

const trackingId = process.env.REACT_APP_TRACKING_ID
ReactGA.initialize(trackingId);

//Initialize MSAL Configuration Instance
const msalInstance = new PublicClientApplication(msalConfig)

ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* <UserContext.Provider value={user}> */}
    <MsalProvider instance={msalInstance}>
      <Router>
        <Routes />
      </Router>
    </MsalProvider>
    {/* </UserContext.Provider> */}
  </ThemeProvider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
createLinkTags()
