import React, { useState, useContext, useMemo, useEffect } from 'react'
import './EditSubscriptions.scss'
import { useHistory } from 'react-router-dom'
import { getkeyvalueText, isFutureCommerceMarket } from '../../util/helperfunctions'
import { MetaText, ToastNotification, ListGrid,BodyText } from '@hm-group/fabric-components'
import { getCustomerDetails, updateSubscription, updateV3Subscription } from '../../Services/DataService'
import { AnalyticsContext } from '../../context/analyticsContext'
import { EVENTACTION, EVENTOUTCOME, EVENTTYPE, PAGE, brandNameFromID } from '../../util/constants'
import { CustomerContext } from '../../context/customerDetailsContext'
import { PrivacyLink } from '../CommonComponents/PrivacyLink'
import { EmailMarketing } from './Components/EmailMarketing'
import { DirectMailMarketing } from './Components/DirectMailMarketing'
import { UserContext } from '../../context/userContext'
import { SubscriptionHeader } from './Components/subscriptionHeader'
import { ActionButtons } from './Components/SubscriptionActionButtons'
import { getBrandConfig, nameConstant } from './subscriptionConfiguraton'
import { Departement } from './Components/Departement'
import { Channel } from './Components/Channel'
import { PhoneField } from './Components/PhoneField'
import { countryOptionsData } from '../../util/CountryCodeContants'

const EditSubscription = () => {
  const history = useHistory()
  const { lslKeyValue } = useContext(UserContext)
  const { customer, updateCustomer } = useContext(CustomerContext)
  const [isInputError, setIsInputError] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [isVisible, setIsVisible] = useState(true);
  const isshowkey = false // check this later
  const {
    businessPartnerId,
    emailAddress,
    corporateBrandId,
    countryCode,
    onlineOMSCustomerIdentifier,
    hmCatalogueSubscription,
    frequencyFashionNews,
    frequencyTextFashionNews,
    clubRole,
    newsLetterSubsciber,
    interests,
    newsLetterSubscriptions
  } = customer

  const isNews =
    frequencyFashionNews?.toLowerCase() === 'reg' ||
    (clubRole === 'z003' && frequencyTextFashionNews?.toLowerCase() === 'regularly') ||
    newsLetterSubsciber

  const [userIsSubscribedToFashionNews, setUserIsSubscribedToFashionNews] = useState(isNews || false)
  const [userIsSubscribedToClubNews, setUserIsSubscribedToClubNews] = useState(hmCatalogueSubscription || false)
  const { emailid = localStorage.getItem('userName'), language } = (lslKeyValue && lslKeyValue.plainres) || {}
  const [channel, setChannel] = useState([])
  const [DepartmentInterests, setDepartmentInterests] = useState(interests ? interests : [])
  const { analyticsData, updateAnalyticsData } = useContext(AnalyticsContext)
  const [departmentError, setDepartmentError] = useState(false)
  const [channelError, setChannelError] = useState(false)
  const [phoneError, setPhoneError] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryPrefix, setCountryPrefix] = useState('')
  const [intialChannels,setInitialChannels]= useState([])
  let [initialPhone,setInitialPhone] = useState('')

  const v3Config = useMemo(() => getBrandConfig(corporateBrandId, countryCode,lslKeyValue), [corporateBrandId, countryCode,newsLetterSubsciber,lslKeyValue])



  useEffect(() => {
    if (v3Config && v3Config.smsMarkets && newsLetterSubscriptions && newsLetterSubscriptions.length) {
      const subscribedChannels = newsLetterSubscriptions
        .filter((each) => each.newsletterSubscription)
        .map((each) => each.type)

      setChannel([...subscribedChannels])
      setInitialChannels([...subscribedChannels])

      const phone = newsLetterSubscriptions.find((each) => each.type === nameConstant.PHONE)
      if (phone) {
        setInitialPhone(phone.value)
        const [countryPrefix, phoneNumber] = phone.value.split(' ')
        setPhoneNumber(phoneNumber)
        setCountryPrefix(countryOptionsData.find((each) => each.prefix.toLowerCase() === countryPrefix.toLowerCase()))
      } else {
        setCountryPrefix(
          countryOptionsData.find((each) => each.countryCode.toLowerCase() === countryCode.toLowerCase()),
        )
      }
    } else if (!newsLetterSubscriptions && v3Config && v3Config.DOI) {
      setChannel([nameConstant.EMAIL])
      setCountryPrefix(countryOptionsData.find((each) => each.countryCode.toLowerCase() === countryCode.toLowerCase()))
    }
    // setting default country prefix as market country code
    else {
      setCountryPrefix(countryOptionsData.find((each) => each.countryCode.toLowerCase() === countryCode.toLowerCase()))
    }
  }, [v3Config, newsLetterSubscriptions])

  useEffect(async () => {
    if (v3Config && v3Config.DOI) {
       await updateCustomerDetails()
    }
  }, [])

  const updateAnalytics = (eventAction, eventOutcome) => {
    updateAnalyticsData({
      ...analyticsData,
      managedCountry: countryCode,
      managedBrand: corporateBrandId,
      eventType: EVENTTYPE.updateSubscription,
      page: PAGE.updateSubscriptionPage,
      eventAction,
      eventOutcome,
      managedBPID: businessPartnerId,
      managedCustomerType: clubRole,
    })
  }

  const handleRadioInput = (e) => {
    switch (e.target.id) {
      case 'emailmarketing-opt-in':
        setUserIsSubscribedToFashionNews(true)
        break
      case 'emailmarketing-opt-out':
        setUserIsSubscribedToFashionNews(false)
        break
      case 'mailmarketing-opt-in':
        setUserIsSubscribedToClubNews(true)
        break
      case 'mailmarketing-opt-out':
        setUserIsSubscribedToClubNews(false)
        break
      default:
        break
    }
  }

  const handleToastClose = () => {
    setIsVisible(false)
  }


  const getEventAction = () => {
    let eventAction = ''
    if (isNews !== userIsSubscribedToFashionNews) {
      eventAction = userIsSubscribedToFashionNews ? EVENTACTION.newsletterEditOptin : EVENTACTION.newsletterEditOptout
    }
    if (hmCatalogueSubscription !== userIsSubscribedToClubNews) {
      eventAction = userIsSubscribedToClubNews
        ? corporateBrandId == 0
          ? `${eventAction ? `${eventAction}/` : ''}${EVENTACTION.directMailEditOptin}`
          : `${eventAction}`
        : corporateBrandId == 0
        ? `${eventAction ? `${eventAction}/` : ''}${EVENTACTION.directMailEditOptout}`
        : `${eventAction}`
    }
    return eventAction
  }

  // TODO : will optimize this and remove this function and update analytics in handleConfirmAction
  const updateCustomerDetails = async () => {
    const tempData = {
      bpid: businessPartnerId,
      brandid: corporateBrandId,
      countrycode: countryCode,
    }

    try {
      const apiResponse = await getCustomerDetails(tempData)
      if (apiResponse.status === 200) {
        const { data } = apiResponse
        // this line is temprory will be remove after creating club role constants
        apiResponse.data.clubRole = apiResponse.data.clubRole.toLowerCase()
        updateCustomer(data)
      }
    } catch (err) {
      // handle error
    }
  }

  const handleConfirmAction = async () => {
    const eventAction = getEventAction()
    if (!eventAction) return

    setIsInputError(false)
    setShowLoader(true)

    const editRequestParam = {
      fashionNewsSubscriber: userIsSubscribedToFashionNews,
      brandId: corporateBrandId,
      countryCode: countryCode,
      customerId: onlineOMSCustomerIdentifier,
      email: emailAddress.toLowerCase(),
      languageCode: lslKeyValue ? lslKeyValue.preferredLanguage : 'EN',
    }

    if (corporateBrandId === '0') {
      editRequestParam.catalogSubscriber = userIsSubscribedToClubNews
    }

    if (isFutureCommerceMarket(brandNameFromID[corporateBrandId], countryCode) || corporateBrandId === '3') {
      editRequestParam.customerId = businessPartnerId
    }

    try {
      const subscriptionData = await updateSubscription(editRequestParam)
      if (subscriptionData.status === 200) {
        // update context here
        setShowLoader(false)

        updateAnalytics(eventAction, EVENTOUTCOME.success)

        await updateCustomerDetails()
        history.push({ pathname: '/ManageServices', state: { isEditRequested: true } })
      } else {
        setIsInputError(true)
        setShowLoader(false)
        updateAnalytics(eventAction, EVENTOUTCOME.failure)
      }
    } catch (err) {
      setIsInputError(true)
      setShowLoader(false)

      updateAnalytics(eventAction, EVENTOUTCOME.failure)
    }
  }

  const handleCancel = () => {
    updateAnalytics(EVENTACTION.updateSubscriptionCancelled, EVENTOUTCOME.success)
    history.push({ pathname: '/ManageServices' })
  }

  const getV3EventAction = () => {
    const isPhoneSubscribed = channel.includes(nameConstant.PHONE);
    const wasPhoneSubscribed = intialChannels.includes(nameConstant.PHONE);
    const isEmailSubscribed = channel.includes(nameConstant.EMAIL);
    const wasEmailSubscribed = intialChannels.includes(nameConstant.EMAIL);
  
    if (userIsSubscribedToFashionNews && v3Config.smsMarkets) {
      if (!v3Config.channels || !userIsSubscribedToFashionNews) return '';
  
      const actions = [];
  
      if (isPhoneSubscribed && !wasPhoneSubscribed) {
        actions.push(EVENTACTION.smsEditOptin);
      } else if (!isPhoneSubscribed && wasPhoneSubscribed) {
        actions.push(EVENTACTION.smsEditOptout);
      }
  
      if (isEmailSubscribed && !wasEmailSubscribed) {
        actions.push(EVENTACTION.newsletterEditOptin);
      } else if (!isEmailSubscribed && wasEmailSubscribed) {
        actions.push(EVENTACTION.newsletterEditOptout);
      }
  
      return actions.join('/');
    } 
  
    if (v3Config.smsMarkets) {
      if (wasPhoneSubscribed && wasEmailSubscribed) {
        return `${EVENTACTION.newsletterEditOptout}/${EVENTACTION.smsEditOptout}`;
      }
      if (wasPhoneSubscribed) {
        return EVENTACTION.smsEditOptout;
      }
      return EVENTACTION.newsletterEditOptout;
    }
  
    return userIsSubscribedToFashionNews 
      ? EVENTACTION.newsletterEditOptin 
      : EVENTACTION.newsletterEditOptout;
  };
  
  
  const handleV3ConfirmAction = async () => {
    const eventAction =  getV3EventAction()
    let isError = false

    if(v3Config.smsMarkets && !channel.length){
      setChannelError(true)
      isError = true
    }

    if(v3Config.categories && v3Config.categories.length && !DepartmentInterests.length){
      setDepartmentError(true)
      isError = true
    }

    if(channel.includes(nameConstant.PHONE) && !phoneNumber){
      setPhoneError(true)
      isError = true
    }
    if(userIsSubscribedToFashionNews && isError){
      return
    }
    const isPhoneChanged = initialPhone !== `${countryPrefix.prefix} ${phoneNumber}`
    setShowLoader(true)
    const payload = {
      bpid: newsLetterSubscriptions && newsLetterSubscriptions.length && businessPartnerId,
      newsletterSubscription: userIsSubscribedToFashionNews,
      emailid: emailid,
      countryCode: countryCode,
      brandId: corporateBrandId,
      subscriptionTypes: channel && channel.length ? channel : [nameConstant.EMAIL],
      postalCode: '',
      language: language,
      phoneNumber: `${countryPrefix.prefix} ${phoneNumber}`,
      interests: DepartmentInterests,
      registeredCustomerBpid  : businessPartnerId,
    }

    try {
      const subscriptionData = await updateV3Subscription(payload)
      if (subscriptionData.status === 200) {
        // update context here
        setShowLoader(false)
        eventAction && updateAnalytics(eventAction, EVENTOUTCOME.success)
        await updateCustomerDetails()
        history.push({
          pathname: '/ManageServices',
          state:
            (v3Config.DOI && channel.includes(nameConstant.PHONE) && isPhoneChanged)
              ? { DOI: true }
              : { isEditRequested: true },
        })
      } else {
        setIsInputError(true)
        setShowLoader(false)
        eventAction && updateAnalytics(eventAction, EVENTOUTCOME.failure)
      }
    } catch (err) {
      setIsInputError(true)
      setShowLoader(false)

      eventAction && updateAnalytics(eventAction, EVENTOUTCOME.failure)
    }
  }
  const handleDepartmentSelect = (value, isChecked,type) => {
    setDepartmentError(false)
    if(type === 'Radio'){
     setDepartmentInterests([value])
    }
    else{
      setDepartmentInterests((prev) => {
        if (isChecked) {
          return [...prev, value]
        } else {
          return prev.filter((each) => each !== value)
        }
      })
    }
   
  }

  const handleChannelSelect = (value, isChecked) => {
    setChannelError(false)
    setChannel((prev) => {
      if (isChecked) {
        return [...prev, value]
      } else {
        return prev.filter((each) => each !== value)
      }
    })
  }

  const onPhoneChange = (value) => {
    setPhoneError(false)
    setPhoneNumber(value)
  }

  const onPrefixChange = (value) => {
    setCountryPrefix(value)
  }

  return (
    <ListGrid numberOfColumns={{ xsmall: 1, small: 1, medium: 1, xlarge: 1 }}>
      <div className="edit__subsciptions">
        {isInputError && (
          <ToastNotification
            autoDelete={false}
            labels={{
              close: 'Close'
            }}
            onClose={handleToastClose}
            isVisible={isVisible}
            message={getkeyvalueText('editSubscriptions', 'updatesubserrormsgLabel', isshowkey, lslKeyValue.lslKeyData)}
            severity="error"
          />
        )}
        {/* header */}
        <SubscriptionHeader corporateBrandId={corporateBrandId} lslKeyValue={lslKeyValue} countryCode={countryCode} />

        <div className="subscriptions__holder">
          {/* email marketing */}
          <EmailMarketing
            userIsSubscribedToFashionNews={userIsSubscribedToFashionNews}
            handleRadioInput={handleRadioInput}
            lslKeyValue={lslKeyValue}
            corporateBrandId={corporateBrandId}
            countryCode={countryCode}
            isCommunicationChannel={v3Config && v3Config.smsMarkets}
          />

          {/* channel */}
          {userIsSubscribedToFashionNews && v3Config && v3Config.smsMarkets && (
            <Channel
              isNewSubscription = {!newsLetterSubscriptions}
              isDOI = {v3Config.DOI}
              channels={v3Config.channels}
              value={channel}
              handleSelect={handleChannelSelect}
              isChannelError={channelError}
              lslKeyValue={lslKeyValue}
            />
          )}

          {/* phone field */}
          {userIsSubscribedToFashionNews &&
            channel &&
            channel.includes(nameConstant.PHONE) &&
            v3Config &&
            v3Config.smsMarkets && (
              <div className='radio-group'>
                <BodyText>
                  {`${getkeyvalueText('editSubscriptions', 'dmphonenumberLabel', false, lslKeyValue.lslKeyData)}*`}
                </BodyText>
                <PhoneField
                  prefix={countryPrefix}
                  phoneNumber={phoneNumber}
                  onPhoneChange={onPhoneChange}
                  onPrefixChange={onPrefixChange}
                  phoneError={phoneError}
                  lslKeyValue={lslKeyValue}
                />
              </div>
            )}

          {/* department */}
          {userIsSubscribedToFashionNews && v3Config && v3Config.preference && (
            <Departement
              preference={v3Config.preference}
              value={DepartmentInterests}
              handleSelect={handleDepartmentSelect}
              categories={v3Config.categories}
              isDepartmentError={departmentError}
              lslKeyValue={lslKeyValue}
            />
          )}

          {/* direct mail market only for HM */}
          {corporateBrandId == '0' ? (
            <DirectMailMarketing
              userIsSubscribedToClubNews={userIsSubscribedToClubNews}
              lslKeyValue={lslKeyValue}
              handleRadioInput={handleRadioInput}
            />
          ) : (
            ''
          )}

          <MetaText className="subscription-disclaimer">
            {getkeyvalueText('editSubscriptions', 'emailsubsdisclaimerLabel', isshowkey, lslKeyValue.lslKeyData)}
          </MetaText>
          <br />
          <MetaText className="subscription-disclaimer">
            {
              getkeyvalueText('editSubscriptions', 'emailsubsadditionalInfo', isshowkey, lslKeyValue.lslKeyData).split(
                '{privacylink}',
              )[0]
            }
          </MetaText>

          {/*privacy link and text*/}
          <PrivacyLink corporateBrandId={corporateBrandId} countryCode={countryCode} lslKeyValue={lslKeyValue} />
        </div>

        {/*Save and Cancel buttons*/}
        <ActionButtons
          handleCancel={handleCancel}
          handleConfirmAction={() => {
            // Subscription v3 changes
            v3Config ? handleV3ConfirmAction() : handleConfirmAction()
          }}
          lslKeyValue={lslKeyValue}
          showLoader={showLoader}
        />
      </div>
    </ListGrid>
  )
}

export default EditSubscription
