import { RadioGroup } from '@hm-group/fabric-components'
import React from 'react'
import { getkeyvalueText } from '../../../util/helperfunctions'

// TODO : create single component for Direct and mail marketing
export const DirectMailMarketing = ({ userIsSubscribedToClubNews, handleRadioInput, lslKeyValue }) => {
  const isshowkey = false
  return (
    <RadioGroup
      defaultActiveId={userIsSubscribedToClubNews ? 'mailmarketing-opt-in' : 'mailmarketing-opt-out'}
      items={[
        {
          id: 'mailmarketing-opt-in',
          name: 'hmMailMarketingSubscription',
          label: `${getkeyvalueText('ManageServices', 'yesLabel', isshowkey, lslKeyValue.lslKeyData)}`,
          testId: 'mailmarketing-opt-in',
        },
        {
          id: 'mailmarketing-opt-out',
          label: `${getkeyvalueText('ManageServices', 'noLabel', isshowkey, lslKeyValue.lslKeyData)}`,
          name: 'hmMailMarketingSubscription',
          testId: 'mailmarketing-opt-out',
        },
      ]}
      label={getkeyvalueText('commonKey', 'directmailsubsLabel', isshowkey, lslKeyValue.lslKeyData)}
      onChange={handleRadioInput}
      className="subscription-radio-grp mailmarketgrp"
    />
  )
}
