import React, { useState, useContext } from 'react'
import './Services.scss'
import Subscription_section from './components/SubscriptionSection'
import AccessDataSection from './components/AccessDataSection'
import DeleteAccountSection from './components/DeleteAccountSection'
import CcpaSection from './components/CcpaSection'
import IconArrowLeft from '../../assets/images/IconArrowLeft.png'
import { ToastNotification, BannerNotification, BodyText, HeadingLarge, ListGrid } from '@hm-group/fabric-components'
import {
  getCountryNamebyCode,
  getDisplayBrandNamebyID,
  getkeyvalueText,
  identifyEvent,
  getBrandImagebyID,
} from '../../util/helperfunctions'
import { CustomerContext } from '../../context/customerDetailsContext'
import { brandNameFromID } from '../../util/constants'
import { useHistory, useLocation } from 'react-router-dom'
import { CustomerDataContext } from '../../context/customerDataContext'
import LightBox from '../LightBoxes/LightBox'
import { UserContext } from '../../context/userContext'

const ManageServices = ({ accordianExpanded, handleToggleAccordian }) => {
  const {lslKeyValue} = useContext(UserContext)

  const [isGuestInfoClicked, setIsGuestInfoClicked] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const { isDataRequested = false, isEditRequested = false, DOI = false } = (location && location.state) || {}

  const { customer } = useContext(CustomerContext)
  const { customerData } = useContext(CustomerDataContext)
  const isshowkey = false // temporary

  const { businessPartnerId, corporateBrandId, countryCode, APRequestStatus } = customer
  const { guestAPRequestStatus } = customerData
  const brandName = brandNameFromID[corporateBrandId]

  const [isVisible, setIsVisible] = useState(true);

  const handleToastClose = () => {
    setIsVisible(false)
  }

  const accessNotificationSection = () => {
    if ((businessPartnerId && APRequestStatus != null) || guestAPRequestStatus != null) {
      return (
        <ToastNotification
          autoDelete={true}
          autoDeleteTime={5000}
          labels={{
            close: 'Close'
          }}
          onClose={handleToastClose}
          isVisible={isVisible}
          message={getkeyvalueText('ManageServices', 'custreqsubmitLabel', isshowkey, lslKeyValue.lslKeyData)}
          severity="success"
        />
      )
    } else {
      return ''
    }
  }

  // fresh code
  const [notifyAP, setNotifyAP] = useState(false)

  const onClose = () => {
    setNotifyAP(false)
  }

  const handleAPClose = (isGuest) => {
    setNotifyAP(true)
    setIsGuestInfoClicked(isGuest)
  }

  return (
    <ListGrid
      numberOfColumns={{
        xsmall: 1,
        small: 1,
        medium: 1,
        xlarge: 1,
      }}
    >
      <div>
        <div className="manage__services_section">
          {/* back button */}
          <div className="overview__section">
            <div
              className="overview_control_section"
              tabIndex={0}
              onClick={() => {
                history.push({ pathname: '/dashboard' })
              }}
              onKeyDown={(event) => {
                identifyEvent(event) && history.push({ pathname: '/dashboard' })
              }}
            >
              <img src={IconArrowLeft} alt="BackToOverview" />
              <BodyText Tag="p" className="overview__section_text">
                {getkeyvalueText('ManageServices', 'bckLabel', isshowkey, lslKeyValue.lslKeyData)}
              </BodyText>
            </div>
          </div>
          {/* data requested or subscription update notification */}
          <div className={'notification__section'}>
            <div style={{ marginRight: '10px' }}>
              {isEditRequested ? (
                <ToastNotification
                  autoDelete={true}
                  autoDeleteTime={5000}
                  labels={{
                    close: 'Close'
                  }}
                  onClose={handleToastClose}
                  isVisible={isVisible}
                  message={getkeyvalueText(
                    'ManageServices',
                    'subscriptionupdatedLabel',
                    isshowkey,
                    lslKeyValue.lslKeyData,
                  )}
                  severity="success"
                />
              ) : isDataRequested ? (
                accessNotificationSection()
              ) : DOI ? (
                // TODO : some optimization here 
                <ToastNotification
                  autoDelete={true}
                  autoDeleteTime={5000}
                  labels={{
                    close: 'Close'
                  }}
                  onClose={handleToastClose}
                  isVisible={isVisible}
                  message={getkeyvalueText(
                    'ManageServices',
                    'sentsmstoconfirmLabel',
                    isshowkey,
                    lslKeyValue.lslKeyData,
                  )}
                  severity="success"
                />
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="brandLogo__title_section">
            <div className="brand__img">
              <img className="brand_puff_logo_manage_page" src={getBrandImagebyID(corporateBrandId)} alt={brandName}></img>
            </div>
            <div className="edit__text" data-testid="edit__text">
              <HeadingLarge Tag="h1">
                {getkeyvalueText('ManageServices', 'mydataLabel', isshowkey, lslKeyValue.lslKeyData)
                  .replace('{Brand}', `${getDisplayBrandNamebyID(corporateBrandId)}`)
                  .replace('{Market}', `${getCountryNamebyCode(countryCode, isshowkey, lslKeyValue.lslKeyData)}`)}
              </HeadingLarge>
            </div>
          </div>
          <div className="services__box">
            <div className="helper__text" data-testid="helper__text">
              <BodyText Tag="p">
                {getkeyvalueText('ManageServices', 'reviewdataLabel', isshowkey, lslKeyValue.lslKeyData)
                  .replace('{Brand}', `${getDisplayBrandNamebyID(corporateBrandId)}`)
                  .replace('{Market}', `${getCountryNamebyCode(countryCode, isshowkey, lslKeyValue.lslKeyData)}`)}
              </BodyText>
            </div>
            <Subscription_section
              updateExpanded={(accordianName) => handleToggleAccordian(accordianName)}
              accordianExpanded={accordianExpanded}
            />
            {countryCode == 'US' && (brandName == 'STORIES' || brandName == 'COS' || brandName == 'HM') ? (
              <CcpaSection />
            ) : (
              ''
            )}
            <AccessDataSection
              updateExpanded={(accordianName) => handleToggleAccordian(accordianName)}
              accordianExpanded={accordianExpanded}
              handleClose={handleAPClose}
            />
            <DeleteAccountSection
              updateExpanded={(accordianName) => handleToggleAccordian(accordianName)}
              accordianExpanded={accordianExpanded}
            />
            <div></div>
          </div>
        </div>
        <LightBox
          brand={brandNameFromID[corporateBrandId]}
          open={notifyAP}
          handleClose={onClose}
          type={'accessPersonalData'}
          isGuestInfoClicked={isGuestInfoClicked}
        />
      </div>
    </ListGrid>
  )
}

export default ManageServices
