import React, { useEffect, useState, useRef, useContext } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types'
import InfoTab from '../../../InfoTab'
import * as qs from 'query-string'
import DataTab from '../../../DataTab'
import _ from 'lodash'
import { getkeyvalueText } from '../../../../util/helperfunctions'
import { useHistory, useLocation } from 'react-router-dom'
import { Tabs } from '@hm-group/fabric-components'
import { UserContext } from '../../../../context/userContext'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#fff',
  },
  indicator: {
    backgroundColor: '#E11F26',
  },

  tabstyle: {
    '& .MuiTab-textColorInherit .Mui-selected': {
      color: '#E11F26 !important',
    },
    textTransform: 'none',
    minWidth: 72,
    fontSize: 12,
    letterSpacing: '1px',
    lineHeight: '15px',
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    cursor: 'pointer',
    color: '#222222',
    fontFamily: theme.typography.h2,
    '&:hover': {
      // color: '#E11F26',
      opacity: 1,
      // cursor : 'pointer',
    },
    '&:selected': {
      color: '#E11F26 !important',
      cursor: 'default',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      cursor: 'default',
      color: '#E11F26',
    },
  },
  tabbed_children: {
    padding: '24px 0',
  },
  tabbedPanel: {
    backgroundColor: theme.palette.secondary.main,
  },
  active_tab: {
    cursor: 'default',
    color: '#E11F26 !important',
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props
  const classes = useStyles()

  return (
    <div
      role="tabpanel"
      className={classes.tabbedPanel}
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  }
}

const AntTabs = withStyles((theme) => ({
  root: {
    borderBottom: '1px solid #e8e8e8',
    backgroundColor: theme.palette.secondary.main,
    //   color : theme.palette.primary
  },
  indicator: {
    backgroundColor: '#E11F26',
    width: '50% !important',
  },
}))(Tabs)

function NavTabs({ handleToggleAccordian, handleListenState }) {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const ref = useRef()
  const {lslKeyValue} = useContext(UserContext)
  const [isshowkey, setisshowkey] = useState(qs.parse(useLocation().search).showkeys)
  let history = useHistory()

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  })

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }


  const getStyle = (isActive) => {
    return !isActive ? classes.active_tab : classes.default_tab
  }


  return (
    <Tabs
      onClick={handleChange}
      tabs={[
        {
          content: <DataTab handleToggleAccordian={handleToggleAccordian} handleListenState={handleListenState} />,
          id: 0,
          label: getkeyvalueText('login', 'mpd_heading', isshowkey, lslKeyValue.lslKeyData),
        },
        {
          content: <InfoTab />,
          id: 1,
          label: getkeyvalueText('LandingPage', 'informationh2Label', isshowkey, lslKeyValue.lslKeyData),
        },
      ]}
      defaultActiveTab={0}
      variant="fixed"
    />
  )
}

export default NavTabs
