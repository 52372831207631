import React from 'react'
import { PhoneNumberCustom } from '../../PhoneNumberSelector/PhoneNumberSelector'
import { BodyText } from '@hm-group/fabric-components'
import { getkeyvalueText } from '../../../util/helperfunctions'

export const PhoneField = ({prefix,phoneNumber,onPhoneChange,onPrefixChange,phoneError,lslKeyValue}) => {
  const prefixSelectProps = {
    value: prefix,
    label: '',
    onSelected: onPrefixChange,
  }

  const phoneNumberSelectorProps = {
    value: phoneNumber,
    label: '',
    onChange: onPhoneChange,
  }
  // TODO : Update this with facbric component
  return (
    <div className='spacing-m'>
    <PhoneNumberCustom prefixSelectProps={prefixSelectProps} phoneNumberSelectorProps={phoneNumberSelectorProps} />
     {/* phone field error */}
     {phoneError && (
            <BodyText Tag="p" className="errorText">
             {getkeyvalueText('editSubscriptions', 'entervalidphonenumberLabel', false, lslKeyValue.lslKeyData)}
            </BodyText>
          )}
    </div>
  )
}
