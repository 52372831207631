import React, { useContext } from 'react'
import { getBrandIdbyName, retrieveCcpaLink } from '../../../util/helperfunctions'
import moment from 'moment'
import _ from 'lodash'
import { getkeyvalueText } from '../../../util/helperfunctions'
import { Accordion, BodyText, HeadingSmall, GhostLink } from '@hm-group/fabric-components'
import { CustomerContext } from '../../../context/customerDetailsContext'
import { UserContext } from '../../../context/userContext'

const CcpaSection = () => {
  const {lslKeyValue} = useContext(UserContext)
  const isshowkey = false // temporary
  const { customer } = useContext(CustomerContext)
  const { ccpaRequestDate, businessPartnerId, corporateBrandId, clubRole ,countryCode} = customer
  

  const momentDate = ccpaRequestDate && moment(new Date(ccpaRequestDate))

  const handle_anchor_click = (brand, country) => {
    window.open(retrieveCcpaLink(brand, country, lslKeyValue), '_blank') //For opening in New Tab
  }

  return (
    <div className="access_section">
      <Accordion
        isExpanded="false"
        accordionItems={[
          {
            content: (
              <div>
                <div>
                  <div className="access__services_text services_msg">
                    <BodyText Tag="p" className="managed_service_text">
                      {getkeyvalueText('ManageServices', 'selectedresidentsLabel', isshowkey, lslKeyValue.lslKeyData)}
                    </BodyText>
                  </div>
                  <div className="align-manage-items">
                    <span>
                      <HeadingSmall Tag="h1" className="align-with-link">
                        {clubRole == 'z002'
                          ? getkeyvalueText('LandingPage', 'holdmembershipLabel', isshowkey, lslKeyValue.lslKeyData)
                          : clubRole == 'z004'
                          ? getkeyvalueText('LandingPage', 'holdaccntLabel', isshowkey, lslKeyValue.lslKeyData)
                          : ''}
                      </HeadingSmall>
                    </span>
                    {businessPartnerId && ccpaRequestDate == null ? (
                      <span>
                        <GhostLink onClick={() => handle_anchor_click(corporateBrandId, countryCode)} tabIndex={0}>
                          {getkeyvalueText('commonKey', 'continuebtn', isshowkey, lslKeyValue.lslKeyData)}
                        </GhostLink>
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  <div>
                    {businessPartnerId && ccpaRequestDate != null ? (
                      <div className="services_request">
                        <BodyText Tag="p" className="services_request_text">
                          {
                            getkeyvalueText('ManageServices', 'ccpareqLabel', isshowkey, lslKeyValue.lslKeyData).split(
                              '{dd mmm yyyy}',
                            )[0]
                          }
                          {String(momentDate.format('DD MMMM YYYY')).replace(
                            momentDate.format('MMMM'),
                            getkeyvalueText('Month', momentDate.format('MMMM'), isshowkey, lslKeyValue.lslKeyData),
                          )}
                          {
                            getkeyvalueText('ManageServices', 'ccpareqLabel', isshowkey, lslKeyValue.lslKeyData).split(
                              '{dd mmm yyyy}',
                            )[1]
                          }
                        </BodyText>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            ),
            heading: (
              <BodyText Tag="p">
                {getkeyvalueText('ManageServices', 'donotsellmydataLabel', isshowkey, lslKeyValue.lslKeyData)}
              </BodyText>
            ),
            id: '1',
          },
        ]}
        defaultId="0"
        onClick={function noRefCheck() {}}
        shouldCollapseSibling
        variant="fill"
      />
    </div>
  )
}

export default CcpaSection
