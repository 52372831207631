import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import InformationSmall from '../../../assets/images/InformationSmall.png'
import { GhostLink, BodyText, HeadingXSmall, InlineLink, HeadingSmall } from '@hm-group/fabric-components'
import {
  getCountryNamebyCode,
  getDisplayBrandNamebyID,
  retrievePrivacyLink,
  getkeyvalueText,
  isCentraMarket,
  identifyEvent,
} from '../../../util/helperfunctions'
import { getCustomerDetails } from '../../../Services/DataService'
import LightBox from '../../LightBoxes/LightBox'
import moment from 'moment'
import MyLoader from '../../../util/ContentLoader'
import _ from 'lodash'
import { InfoIcon } from '@hm-group/fabric-icons'
import { AnalyticsContext } from '../../../context/analyticsContext'
import SessionStorageService from '../../../Services/SessionStorageService'
import { EVENTACTION, EVENTOUTCOME, EVENTTYPE, PAGE, brandNameFromID } from '../../../util/constants'
import { CustomerContext } from '../../../context/customerDetailsContext'
import { CustomerDataContext } from '../../../context/customerDataContext'
import { UserContext } from '../../../context/userContext'

const DataBox = ({ data }) => {
  const { businessPartnerId, corporateBrandId, countryCode, guestBusinessPartnerId, guestCICFlag, guestCICDate } = data
  const { lslKeyValue } = useContext(UserContext)
  const { updateCustomer } = useContext(CustomerContext)
  const { updateCustomerData } = useContext(CustomerDataContext)
  const isshowkey = false // temporary
  const [isOpen, setisOpen] = useState(false)
  const [isError, setIsError] = useState(false)
  const [customerdetails, setcustomerdetails] = useState({})
  const [showLoader, setshowLoader] = useState(true)
  let history = useHistory()
  const [pageRefresh, setpageRefresh] = useState(0)
  const [type, settype] = useState('')
  const { analyticsData, setAnalyticsData, updateAnalyticsData } = useContext(AnalyticsContext)
  const [isGuestInfoClicked, setIsGuestInfoClicked] = useState(false)

  //Logic For Getting Customer Details for the current Customer
  useEffect(async () => {
    setIsError(false)
    setshowLoader(true)
    const tempData = {
      bpid: businessPartnerId,
      brandid: corporateBrandId,
      countrycode: countryCode,
    }
    try {
      if (!(businessPartnerId == null && guestBusinessPartnerId != null)) {
        const apiResponse2 = await getCustomerDetails(tempData)
        if (apiResponse2.status === 200 && businessPartnerId === analyticsData.sourceBPID) {
          setAnalyticsData((prev) => {
            return { ...prev, sourceCustomerType: apiResponse2.data.clubRole }
          })
          SessionStorageService.setItem('sourceCustomerType', { sourceCustomerType: apiResponse2.data.clubRole })
        }

        setcustomerdetails(apiResponse2.data)
        retrieveCustomerName(apiResponse2.data)
        // this line is temprory will be remove after creating club role constants
        apiResponse2.data.clubRole = apiResponse2.data.clubRole.toLowerCase()
        setshowLoader(false)
        setIsError(false)
      } else {
        setshowLoader(false)
        setIsError(true)
      }
    } catch (err) {
      setshowLoader(false)
      setIsError(true)
    }
  }, [pageRefresh])

  //Get and Save Customer Name

  const retrieveCustomerName = (data) => {
    const name = data && data.firstName
    if (name != null || name != undefined) {
      localStorage.setItem('customerIdentifier', name)
    }
  }

  //PopUp open Logic
  const setModalType = (type) => {
    if (type) {
      updateCustomer(customerdetails)
      updateCustomerData(data)
      settype(type)
      setisOpen(true)
    }
  }

  const onModalClose = () => {
    setisOpen(false)
  }

  const handlePrivacyAction = (brand, country) => {
    window.open(retrievePrivacyLink(brand, country, lslKeyValue), '_blank')
  }

  const handleAnchorClick = async () => {
    // TODO : some refactoring needed
    const tempData = {
      bpid: businessPartnerId,
      brandid: corporateBrandId,
      countrycode: countryCode,
    }
    const apiResponse = await getCustomerDetails(tempData)
    if (apiResponse.status === 200) {
      // this line is temprory will be remove after creating club role constants
      apiResponse.data.clubRole = apiResponse.data.clubRole.toLowerCase()
      updateCustomer(apiResponse.data)
    }

    updateCustomerData(data)


  // New conditions for specific club roles and hybris ID
  if ( customerdetails.onlineOMSCustomerIdentifier === null) {

    if(customerdetails.clubRole === 'z004' || customerdetails.clubRole === 'z002'){
      setModalType('manageService');
      return;
    }

    if(customerdetails.clubRole === 'z003' || customerdetails.clubRole === 'z000'){
      setModalType('customerService');
      return;
    }

  }

    if (guestBusinessPartnerId != null && customerdetails && customerdetails.revokeConsent) {
      setModalType('customerService')
      return
    }

    if (isError) {
      setModalType('customerService')
      return
    }

    if (customerdetails && customerdetails.onlineOMSCustomerIdentifier == null && !isError) {
      setModalType('customerService')
      return
    }

    if (
      customerdetails.clubRole == 'z003' &&
      customerdetails.frequencyTextFashionNews && customerdetails.frequencyTextFashionNews.toLowerCase() == 'regularly' &&
      isCentraMarket(brandNameFromID[customerdetails.corporateBrandId], customerdetails.countryCode)
    ) {
      setModalType('customerService')
      return
    }
    setshowLoader(true)

    updateAnalyticsData({
      ...analyticsData,
      managedCountry: customerdetails.countryCode,
      managedBrand: corporateBrandId,
      eventType: EVENTTYPE.overview,
      page: PAGE.overviewPage,
      eventAction: EVENTACTION.manageServiceLink,
      eventOutcome: EVENTOUTCOME.success,
      managedBPID: businessPartnerId,
      managedCustomerType: customerdetails.clubRole,
    })

    history.push({
      pathname: '/ManageServices',
    })
  }

  const getDateText = (date) => {
    if (date) {
      return String(moment(new Date(date)).format('DD MMMM YYYY'))
        .replace(
          moment(new Date(date)).format('MMMM'),
          getkeyvalueText('Month', moment(new Date(date)).format('MMMM'), isshowkey, lslKeyValue.lslKeyData),
        )
        .trim()
    } else {
      return ''
    }
  }

  const checkIsSubscription = () => {
    if (customerdetails) {
      if (customerdetails.frequencyFashionNews && customerdetails.frequencyFashionNews.toLowerCase() === 'reg') {
        return true
      } else if (
        customerdetails.clubRole === 'z003' &&
        customerdetails.frequencyTextFashionNews &&
        customerdetails.frequencyTextFashionNews.toLowerCase() === 'regularly'
      ) {
        return true
      } else if (customerdetails.hmCatalogueSubscription && customerdetails.corporateBrandId === '0') {
        return true
      }
      else if (customerdetails.newsLetterSubsciber) {
        return true
      }
    }
    return false
  }

  const checkIsMembership = () => {
    if (customerdetails) {
      if (customerdetails.clubMemberStatus !== 'active') {
        if (
          customerdetails.clubRole === 'z002' ||
          customerdetails.clubRole === 'z006' ||
          customerdetails.clubRole === 'z004'
        ) {
          return true
        }
      }

      if (
        customerdetails.clubRole === 'z003' &&
        customerdetails.onlineOMSCustomerIdentifier != null &&
        !isCentraMarket(brandNameFromID[corporateBrandId], customerdetails.countryCode)
      ) {
        return true
      }

      if (customerdetails.clubMemberStatus == 'active') {
        return true
      }
    }
    return false
  }

  const getDeleteLabel = (index) => {
    return getkeyvalueText('LandingPage', 'deleterequestLabel', isshowkey, lslKeyValue.lslKeyData).split(
      '{dd mmm yyyy}',
    )[index]
  }

  const renderPrivacyLink = () => (
    <div className="privacy__anchor">
      <GhostLink
        iconPosition="start"
        title="title"
        data-testid="privacy_link"
        target="_blank"
        onClick={() => {
          handlePrivacyAction(corporateBrandId, countryCode)
        }}
        tabIndex={0}
        onKeyDown={(event) => {
          identifyEvent(event) && handlePrivacyAction(corporateBrandId, countryCode)
        }}
      >
        {getkeyvalueText('LandingPage', 'privacynoticeLabel', isshowkey, lslKeyValue.lslKeyData)}
      </GhostLink>
    </div>
  )

  const renderRetryLink = () => (
    <div className="delete__service_err text-alignment">
      <BodyText Tag="p">
        {getkeyvalueText('forgotpwd', 'tryagnLabel', isshowkey, lslKeyValue.lslKeyData)}
        &nbsp;
      </BodyText>

      <InlineLink
        className="err_link"
        onClick={() => setpageRefresh(pageRefresh + 1)}
        onKeyDown={(event) => {
          identifyEvent(event) && setpageRefresh(pageRefresh + 1)
        }}
      >
        {getkeyvalueText('LandingPage', 'retryloadingLabel', isshowkey, lslKeyValue.lslKeyData)}
      </InlineLink>
    </div>
  )

  const shouldRenderContunueLink = () => {
    if (customerdetails && customerdetails.revokeConsent) {
      return guestBusinessPartnerId !== null && !guestCICFlag
    }
    return true
  }

  const renderContinueLink = () => (
    <GhostLink
      data-testid="manage_link"
      onClick={handleAnchorClick}
      onKeyDown={(event) => {
        identifyEvent(event) && handleAnchorClick()
      }}
      tabIndex={0}
    >
      {getkeyvalueText('LandingPage', 'manageAnchorbtn', isshowkey, lslKeyValue.lslKeyData)}
    </GhostLink>
  )

  const renderGuestSection = () => {
    if (guestBusinessPartnerId != null && !guestCICFlag) {
      return (
        <div>
          <HeadingXSmall Tag="h1">
            {getkeyvalueText('LandingPage', 'holdguestaccntLabel', isshowkey, lslKeyValue.lslKeyData)}
          </HeadingXSmall>
        </div>
      )
    } else if (guestBusinessPartnerId != null && guestCICFlag) {
      return (
        <>
          <div className="hmm__ul_list _zeromargin">
            <div>
              <HeadingXSmall Tag="h1">
                {getkeyvalueText('LandingPage', 'holdguestaccntLabel', isshowkey, lslKeyValue.lslKeyData)}
              </HeadingXSmall>
            </div>
          </div>
          <div
            className="delete__services"
            onClick={() => {
              setIsGuestInfoClicked(true)
              setModalType('notifydelete')
            }}
          >
            <InfoIcon size="small" className="infoIconMargin" />
            <BodyText Tag="p">
              {getDeleteLabel(0)}
              {getDateText(guestCICDate)}
              {getDeleteLabel(1)}
            </BodyText>
          </div>
        </>
      )
    } else {
      return null
    }
  }

  return (
    <div className="hm__data_box" data-testid="hm_databox">
      <div>
        {/* brand name and continue link */}
        <div className="flex__box">
          <div>
            <HeadingSmall Tag="h1" data-testid="databox_brandMarketName">
              {getDisplayBrandNamebyID(corporateBrandId)},{' '}
              {getCountryNamebyCode(countryCode, isshowkey, lslKeyValue.lslKeyData)}
            </HeadingSmall>
          </div>

          {shouldRenderContunueLink() && renderContinueLink()}
        </div>

        <div className="marginBotton">
          {/* loader section */}
          {showLoader ? (
            <div className="loader_area">
              <MyLoader width={250} height={250} />
            </div>
          ) : (
            ''
          )}

          {/* delete details section */}
          {customerdetails && customerdetails.revokeConsent ? (
            <>
              <div className="hm__ul_list_with_indent">
                <div>
                  <HeadingXSmall Tag="h1">
                    {getkeyvalueText('LandingPage', 'holdaccntLabel', isshowkey, lslKeyValue.lslKeyData)}
                  </HeadingXSmall>
                </div>
              </div>
              <div
                className="delete__services"
                onClick={() => {
                  setModalType('notifydelete')
                }}
                tabIndex={0}
              >
                <InfoIcon size="small" className="infoIconMargin" />
                <BodyText Tag="p">
                  {getDeleteLabel(0)}
                  {getDateText(customerdetails.consentRevocationDate)}
                  {getDeleteLabel(1)}
                </BodyText>
              </div>
              {/* Guest delete section */}
              {renderGuestSection()}
            </>
          ) : (
            <div className="hm__ul_list">
              {/* label section */}
              {checkIsSubscription() ? (
                <div>
                  <HeadingXSmall Tag="h1">
                    {getkeyvalueText('ManageServices', 'editsubsLabel', isshowkey, lslKeyValue.lslKeyData)}
                  </HeadingXSmall>
                </div>
              ) : (
                ''
              )}

              {checkIsMembership() ? (
                <div>
                  <HeadingXSmall Tag="h1">
                    {customerdetails && customerdetails.clubRole == 'z002'
                      ? getkeyvalueText('LandingPage', 'holdmembershipLabel', isshowkey, lslKeyValue.lslKeyData)
                      : (customerdetails && customerdetails.clubRole == 'z004') || customerdetails.clubRole == 'z003'
                      ? getkeyvalueText('LandingPage', 'holdaccntLabel', isshowkey, lslKeyValue.lslKeyData)
                      : ''}
                  </HeadingXSmall>
                </div>
              ) : (
                ''
              )}

              {/* Guest label section */}
              {renderGuestSection()}
            </div>
          )}

          {/* retry link  */}
          {isError && guestBusinessPartnerId == null ? renderRetryLink() : ''}
        </div>
        {/* privacy link */}
        {renderPrivacyLink()}
      </div>
      <LightBox
        brand={brandNameFromID[corporateBrandId]}
        country={countryCode}
        open={isOpen}
        handleClose={onModalClose}
        commonRedirect={onModalClose}
        type={type}
        isGuestInfoClicked={isGuestInfoClicked}
      />
    </div>
  )
}

export default DataBox
