import React, {useContext} from 'react';
import { brandsLogo } from '../../../assets/images';
import { getkeyvalueText } from '../../../util/helperfunctions';
import { BodyText } from '@hm-group/fabric-components'
import { UserContext } from '../../../context/userContext';


export const WelcomeUser = (isshowkey) => {

    const {lslKeyValue} = useContext(UserContext);

    return (
        <div>
            <BodyText alignment="center" Tag="p">{getkeyvalueText('Welcomepop', 'privacyrightsLabel', isshowkey, lslKeyValue.lslKeyData)}</BodyText>
            <BodyText alignment="center" Tag="p">{getkeyvalueText('Welcomepop', 'handleDataLabel', isshowkey, lslKeyValue.lslKeyData)}</BodyText>
        </div>
    );
}

export const Brand_Logo_Section = () => {
    return (
        <div className="brandLogos">
            {
                brandsLogo.filter(e => e.brandId != 10).map(el => <img key={el.brandId} src={el.logo} />)
            }
        </div>
    );
}

