import { brandNameFromID } from "../../util/constants"
import { getkeyvalueText, isCentraMarket } from "../../util/helperfunctions"

export const ArketConfig = {
  smsMarkets: ['DK', 'FR'],
  categories: [
    { id: 'WOMEN', label: 'womeninterestLabel' },
    { id: 'MEN', label: 'meninterestLabel' },
    { id: 'CHILDREN', label: 'childreninterestLabel' },
    { id: 'HOMEWARE', label: 'homewareinterestLabel' },
  ],
  channels : ['EMAIL','PHONE'],
  preference: 'Checkbox',
  DOI: [],
}

export const CosConfig = {
  smsMarkets: ['DE','FR'],
  categories: [
    { id: 'WOMEN', label: 'womeninterestLabel' },
    { id: 'MEN', label: 'meninterestLabel' },
  ],
  channels : ['EMAIL','PHONE'],
  preference: 'Radio button',
  DOI: ['DE'],
}

export const StoriesConfig = {
  smsMarkets: ['DK','NL','AT','FR'],
  channels : ['EMAIL','PHONE'],
  categories: [],
  DOI: [],
}


export const WeekdayConfig = {
  preference: 'Radio button',
  categories: [
    { id: 'WOMEN', label: 'womeninterestLabel' },
    { id: 'MEN', label: 'meninterestLabel' },
  ],
}

const CronusConfig = {
  6: ArketConfig,
  1: CosConfig,
  3: WeekdayConfig,
  5: StoriesConfig,
}

export const getBrandConfig = (brandId, countryCode,lslKeyValue) => {
  const config =  isCentraMarket(brandNameFromID[brandId],countryCode) && CronusConfig[brandId]
  if (!config) {
    return false
  }

  return {
    preference: config.preference,
    categories: config.categories.map((category) => {
      return {
        id: category.id,
        label: getkeyvalueText('editSubscriptions', category.label, false, lslKeyValue.lslKeyData),
      }
    }),
    smsMarkets: config.smsMarkets && config.smsMarkets.includes(countryCode),
    DOI: config.DOI && config.DOI.includes(countryCode),
    channels: config.channels,
  }
}

export const HMConfig = {
  notDirectMail: ['South Korea'],
}

export const nameConstant = Object.freeze({
  'PHONE' : 'PHONE',
  'EMAIL' : 'EMAIL',
})
