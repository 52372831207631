import { CheckboxInput, RadioGroup, BodyText } from '@hm-group/fabric-components'
import React, { memo } from 'react'
import { getkeyvalueText } from '../../../util/helperfunctions'

const CheckboxDepartment = memo(({ handleSelect, isChecked, label,id }) => (
  <CheckboxInput
    className="spacing-s"
    labels={{
      errorLabel: 'Error message',
      label: label,
    }}
    isChecked={isChecked}
    name={`Checkbox-${label}`}
    onChange={(e) => handleSelect(id, e.target.checked,'Checkbox')}
  />
))

const RadioDepartment = memo(({ handleSelect, categories, defaultActiveId }) => (
  <RadioGroup
    className="spacing-s"
    defaultActiveId={defaultActiveId}
    errorLabel="Error message"
    items={categories}
    onChange={(e) => handleSelect(e.target.id, true, 'Radio')}
  />
))

export const Departement = ({ preference, value, handleSelect, categories, isDepartmentError,lslKeyValue }) => {
  const isshowkey = false;
  return (
    <div className="spacing-m">
      <div className= { preference === 'Checkbox' ? 'spacing-m' : ''}>
        <BodyText> {getkeyvalueText('editSubscriptions', 'depofinterestLabel', isshowkey, lslKeyValue.lslKeyData)}</BodyText>
      </div>
      <div className="radio-group">
        {preference === 'Checkbox' ? (
          categories.map((each) => (
            <CheckboxDepartment label={each.label} handleSelect={handleSelect} isChecked={value.includes(each.id)} id = {each.id} />
          ))
        ) : (
          <RadioDepartment
            defaultActiveId={value[0]}
            handleSelect={handleSelect}
            categories={categories}
          />
        )}
        {isDepartmentError && (
          <BodyText Tag="p" className="errorText">
            {getkeyvalueText('A&PFeedback', 'apselectoption', isshowkey, lslKeyValue.lslKeyData)}
          </BodyText>
        )}
      </div>
     
    </div>
  )
}
