import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { getkeyvalueText, identifyEvent } from '../../../util/helperfunctions'
import { Accordion, BodyText, HeadingXSmall, MetaText, GhostLink } from '@hm-group/fabric-components'
import { EVENTACTION, EVENTOUTCOME, EVENTTYPE, PAGE } from '../../../util/constants'
import { AnalyticsContext } from '../../../context/analyticsContext'
import { CustomerContext } from '../../../context/customerDetailsContext'
import { UserContext } from '../../../context/userContext'

const SubscriptionSection = ({ updateExpanded, accordianExpanded }) => {
  const history = useHistory()
  const {lslKeyValue} = useContext(UserContext)
  const { analyticsData, updateAnalyticsData } = useContext(AnalyticsContext)
  const isshowkey = false

  const { customer } = useContext(CustomerContext)
  const {
    businessPartnerId,
    corporateBrandId,
    countryCode,
    hmCatalogueSubscription,
    frequencyFashionNews,
    frequencyTextFashionNews,
    clubRole,
    newsLetterSubsciber,
  } = customer

  const handleSubscriptionUpdate = () => {
    updateAnalyticsData({
      ...analyticsData,
      managedCountry: countryCode,
      managedBrand: corporateBrandId,
      eventType: EVENTTYPE.updateSubscription,
      page: PAGE.updateSubscriptionPage,
      eventAction: EVENTACTION.UpdateSubscriptionEdit,
      eventOutcome: EVENTOUTCOME.success,
      managedBPID: businessPartnerId,
      managedCustomerType: clubRole,
    })
    history.push({ pathname: '/editSubscriptions' })
  }

  const renderSubscriptionStatus = (condition) => (
    <MetaText Tag="p" className="managed_service_text">
      {getkeyvalueText('ManageServices', condition ? 'yesLabel' : 'noLabel', isshowkey, lslKeyValue.lslKeyData)}
    </MetaText>
  )

  return (
    <div className="edit__subscriptions_box">
      <Accordion
        isExpanded={accordianExpanded['subscriptionAccord']}
        onClick={() => updateExpanded('subscriptionAccord')}
        accordionItems={[
          {
            content: (
              <div className="flexdiv">
                <div>
                  <HeadingXSmall Tag="h1" className="line-height align-with-link">
                    {getkeyvalueText('commonKey', 'emailsubsLabel', isshowkey, lslKeyValue.lslKeyData)}
                  </HeadingXSmall>
                  {businessPartnerId &&
                    renderSubscriptionStatus(
                      (frequencyFashionNews && frequencyFashionNews.toLowerCase() === 'reg') ||
                        (clubRole === 'z003' &&
                          frequencyTextFashionNews &&
                          frequencyTextFashionNews.toLowerCase() === 'regularly') || newsLetterSubsciber,
                    ) }
                  {corporateBrandId === '0' && (
                    <>
                      <HeadingXSmall Tag="h1" className="align-with-link">
                        {getkeyvalueText('commonKey', 'directmailsubsLabel', isshowkey, lslKeyValue.lslKeyData)}
                      </HeadingXSmall>
                      {businessPartnerId && renderSubscriptionStatus(hmCatalogueSubscription ? hmCatalogueSubscription : false)}
                    </>
                  )}
                </div>
                <div className="anchoricon">
                  <GhostLink
                    tabIndex={0}
                    onClick={handleSubscriptionUpdate}
                    onKeyDown={(event) => {
                      if (identifyEvent(event)) handleSubscriptionUpdate()
                    }}
                  >
                    {getkeyvalueText('ManageServices', 'editbtnLabel', isshowkey, lslKeyValue.lslKeyData)}
                  </GhostLink>
                </div>
              </div>
            ),
            heading: (
              <BodyText Tag="p">
                {getkeyvalueText('ManageServices', 'editsubsLabel', isshowkey, lslKeyValue.lslKeyData)}
              </BodyText>
            ),
            id: '1',
          },
        ]}
        defaultId="0"
        shouldCollapseSibling
        variant="fill"
      />
    </div>
  )
}

export default SubscriptionSection
