import React, { useState } from 'react'
import { Select, DropDownItem, TextInput } from '@hm-group/fabric-components'
import { countryOptionsData } from '../../util/CountryCodeContants'
import './PhoneNumberSelector.scss'

export const PhoneNumberCustom = ({
  prefixSelectProps,
  phoneNumberSelectorProps,
}) => {
  const [phoneNumberValue, setPhoneNumberValue] = useState(phoneNumberSelectorProps.value || '')
  const [selectedPrefix, setSelectedPrefix] = useState(prefixSelectProps.value || { prefix: '', countryCode: '',label: '' })

  const handlePrefixChange = (selectedValue) => {
    const selectedCountry = countryOptionsData[selectedValue.index]
    const country = {
      prefix: selectedCountry.prefix,
      countryCode: selectedCountry.countryCode,
      label: selectedCountry.label,
    }
    setSelectedPrefix(country)
    prefixSelectProps.onSelected(country)
  }

  const handlePhoneNumberChange = (e) => {
    const value = e.currentTarget.value
    setPhoneNumberValue(value)
    phoneNumberSelectorProps.onChange(value)
  }

  return (
    <section  className='container'>
      <Select
        ariaLabel="Select Country Prefix"
        helperText={prefixSelectProps.helperText}
        isReadOnly
        testId={prefixSelectProps.testId}
        label={prefixSelectProps.label}
        name={prefixSelectProps.name || 'prefix'}
        onSelected={handlePrefixChange}
        placeholder={prefixSelectProps.placeholder || 'Select ...'}
        value={`${selectedPrefix.prefix}`}
        isInvalid={prefixSelectProps.isInvalid}
      >
        {countryOptionsData.map((country) => (
          <DropDownItem
            key={country.countryCode}
            label={`${country.countryCode} (${country.prefix})`}
            data-testid={country.countryCode}
          />
        ))}
        
      </Select>

      <TextInput
        helperText={phoneNumberSelectorProps.helperText}
        id={phoneNumberSelectorProps.id}
        label={phoneNumberSelectorProps.label}
        name={phoneNumberSelectorProps.name || 'cellPhone'}
        isInvalid={phoneNumberSelectorProps.isInvalid}
        isValid={phoneNumberSelectorProps.isValid}
        onChange={handlePhoneNumberChange}
        placeholder={phoneNumberSelectorProps.placeholder || '000 00000'}
        value={phoneNumberValue}
        testId={phoneNumberSelectorProps.testId}
        isRequired={phoneNumberSelectorProps.isRequired}
      />
    </section>
  )
}


