import axios from 'axios'
import * as qs from 'query-string';
import {APIHOST,AUTHAPIHOST, Client_id ,Client_secret} from '../util/util';

export const postLogin = (postData) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    return (
            axios
                // .get('https://jsonplaceholder.typicode.com/todos/1')
                .post('/login',qs.stringify(postData),{
                    headers: headers
                })
                )
}

export const retrieveCountry = (postData) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    return (
            axios
                // .get('https://jsonplaceholder.typicode.com/todos/1')
                .post('/retrieveCountry',qs.stringify(postData),{
                    headers: headers
                })
                )
}