import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import HMGrpLogo from '../../../../assets/images/hm_group_logo.svg'
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useLocation, useHistory } from 'react-router-dom';
import { getkeyvalueText } from '../../../../util/helperfunctions';
import { BodyText } from '@hm-group/fabric-components'
import './AppBar.scss'
import { UserContext } from '../../../../context/userContext';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar_root: {
    margin: '2% 0%'
  },
  menuButton: {
    // marginRight: theme.spacing(2),
    color: 'black'
  },
  title: {
    flexGrow: 1,
  },
  appbar: {
    backgroundColor: '#FAF9F8',
    boxShadow: 'none'
  },
  sectionMobile : {
    color : 'black'
  }
}));


const App_Bar = ({ isLogin, handleClose, user, userName, isshowkey }) => {
  // const [isLogin, setIsLogin] = React.useState(path)
  const {lslKeyValue} = useContext(UserContext);
  const classes = useStyles();
  let history = useHistory();
  const search = useLocation().pathname;
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const menuId = 'primary-search-account-menu';
  let searchStringSplit = (search.toLowerCase()).split("/")[1];
  let islogoff = searchStringSplit == "logoff" ? true : false;
  let isLoginBox = (searchStringSplit == "login") || searchStringSplit == "" ? true : false;
  let isErrorPage = (searchStringSplit == "errorpage") || searchStringSplit == "" ? true : false;

  //If Already Logged In, Capture Login Identifier from Session Storage
  const isLoggedIn = localStorage.getItem("isLoggedIn");
    let isUser = user;
    if (isLoggedIn) {
        isUser = true;
    }

  let transport_data = {
      userName : userName
  }
  const handleAnchorClick = () => {
    if (history.location.pathname !== '/dashboard') {
      history.push({ pathname: '/dashboard', state: transport_data })
    }
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar className={classes.toolbar_root}>
          <Typography variant="h6" className={classes.title}>
            <a onClick={handleAnchorClick}>
              <img className="hm__group_logo" src={HMGrpLogo} alt="H&M Group"></img>
              <span className="hide-content notranslate">H&M Hennes & Mauritz</span></a>
          </Typography>

          <div className={classes.sectionMobile} onClick={handleClose}>
            {!(islogoff || isLoginBox || isErrorPage) ?
              <BodyText Tag="p">
                {getkeyvalueText('LandingPage','logoutbtnLabel', isshowkey, lslKeyValue.lslKeyData)}
                </BodyText>
              // <IconButton
              //   edge="end"
              //   aria-label="account of current user"
              //   aria-controls={menuId}
              //   aria-haspopup="true"
              //   onClick={handleClose}
              //   color="inherit"
              // >
              //   <a className="menu__myhm" data-remodal-trigger="club-signin" data-signin-state="guest"><span className="hide-content notranslate">Sign In</span></a>
              // </IconButton>
              : ""
            }
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
App_Bar.propTypes = {
  isLogin: PropTypes.bool,
  user: PropTypes.bool,
  handleClose: PropTypes.func,
  userName : PropTypes.string,
  isshowkey : PropTypes.string 
}
export default App_Bar;